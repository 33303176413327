import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useNavigate } from 'react-router-dom'
import { getTribeById } from '../../../config/tribes'

type GladiatorSectionProps = {
    id: string
    isTextFirst?: boolean
}

export default function TribeSection({ id, isTextFirst = false } : GladiatorSectionProps){

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)')
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)')
    const tribe = getTribeById(id)
    isTextFirst = xs ? false : isTextFirst

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            cursor: 'pointer'
        },
        imageGrid: {
            height: sm ? '40vh' : '60vh',
            marginRight: xs ? 0 : 5,
            marginLeft: xs ? 0 : 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'transparent'
        },
        img: {
            height: 450,
        },
        textGrid: {
            marginRight: isTextFirst && xs ? 0 : 30,
            marginLeft: xs ? 0 : 30,
            whiteSpace: 'pre-line',
            maxHeight: 350,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
        spacing: {
            width: '100%',
            height: 90
        }
    }))

    const classes = useStyles()
    const navigate = useNavigate()

    const ImageGrid = () => {
        return (
            <Grid xs={12} sm={4} md={4} lg={4} xl={4} item={true}>
                <div className={classes.imageGrid}>
                    <img className={classes.img} alt="" src={`/img/${tribe.image1}`}/>
                </div>
            </Grid>
        )
    }

    const TextGrid = () => {
        return (
            <Grid xs={12} sm={8} md={8} lg={8} xl={8} item={true}>
                <div className={classes.textGrid}>
                    <h1>{tribe.title}</h1>
                    <p>{tribe.description1}</p>
                </div>
            </Grid>
        )
    }

    const Spacing = () => <div className={classes.spacing}></div>

    return (
        <Grid 
            className={classes.root} 
            container 
            justifyContent="center" 
            item={true}
            onClick={()=> {
                if(tribe.unrevealed){
                    navigate(`/tribe/${id}`)
                } else {
                    alert('Not available yet')
                }
            }}>
            {isTextFirst ? (
                <>
                 <TextGrid/>
                 <ImageGrid/>
                 </>
            ) : (
                <>
                <ImageGrid/>
                <TextGrid/>
                </>
            )}
            <Spacing/>
        </Grid>
    )
}

