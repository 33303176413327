import {
  environment,
  networkId,
  chainId,
  gladiatorContractAddress,
  degenArenaContractAddress,
} from "./blockchain";

const colors = {
  color1: "#BCF8EC",
  color2: "#AED9E0",
  color3: "#9FA0C3",
  color4: "#8B687F",
  color5: "#7B435B",
  placeholder: "#1f2124",
  background: "black",
  text: "white",
};

const font = {
  family: "Radiance",
  size: {
    small: 18,
    normal: 22,
    large: 28,
    xl: 32,
  },
};

const menu = [
  {
    title: "Start",
    url: "",
  },
  {
    title: "Roadmap",
    url: "roadmap",
  },
  {
    title: "Degen Arena",
    url: "degen-arena",
  },
  {
    title: "Tribes",
    url: "tribes",
  },
  {
    title: "Mint",
    url: "mint",
  },
];

const links = {
  twitter: "https://twitter.com/metagladiators",
  github: "https://github.com/metagladiators",
  opensea: "https://opensea.io/collection/meta-gladiators",
  gitbook: "https://meta-gladiators.gitbook.io/whitepaper",
  discord: "https://discord.gg/HQPGzVeH98",
  medium: "",
  gitbookGladiatorPoints:
    "https://meta-gladiators.gitbook.io/whitepaper/2.0-game-play-p2e/2.1-gladiators-points",
  openSeaToken:
    "https://opensea.io/assets/ethereum/0xe368cd117112bd229145ccec5bf391fc1f1aa299/",
};

const paths = {
  placeholder: "/img/placeholder-image.png",
  start: {
    coverImage: "/img/start-cover.png",
  },
  degenArena: {
    open: "/img/arenas/degen_arena_open.png",
    closed: "/img/arenas/degen_arena_closed.png",
  },
};

const apis = {
  blocknative: {
    key: "a0d0f047-7668-4e61-a38a-17013d5d66a8",
  },
};

const mint = {
  date: new Date(1656608400000),
};
export {
  environment,
  networkId,
  chainId,
  gladiatorContractAddress,
  degenArenaContractAddress,
  colors,
  font,
  menu,
  links,
  paths,
  apis,
  mint,
};
