const arena1 = {
  id: "deathmatch",
  title: "The Deathmatch Arena",
  logo: "/img/arenas/arena1-logo.png",
  cover: "/img/arenas/arena1-cover.png",
  description: `The Deathmatch Arena is a combat arena available for contestants on a weekly basis. Every week the arena will open for registration for gladiators who are willing to battle for a chance to win amazing prizes and - maybe even more importantly - precious experience points.`,
  description2: `Contestants will be able to enter as many gladiators (NFTs) as they hold until registration has closed. The available spots to enter gladiators may however be limited to certain amounts during some weeks.
    
    Once registration has closed, each registered gladiator will be paired up against another gladiator registered within the same Gladiator point bracket as themselves. Battle in the Deathmatch Arena will commence as described in the whitepaper , and a winner and a loser will be determined in each match. The winner will receive experience points which will help the warrior become stronger and hence more likely to win future battles, while the loser will have to lick their wounds and return home empty handed.
    `,
  description3: `Each week the team will announce the prizes available in the weekly Deathmatch Arena. Prizes will vary from week to week and also depending on if it is free or not to enter the arena, the amounts of spots available and other relevant factors.
    
    🤑 Ether
    🏆 Additional Meta Gladiators NFTs
    💎 Blue chip NFTs
    🥇 Whitelist spots to other projects
    🎁 Real world items`,
};
const arena2 = {
  id: "tribewars",
  title: "The Tribe Wars",
  logo: "/img/arenas/arena2-logo.png",
  cover: "",
  description: `Tribe Wars is a unique concept giving all gladiators a monthly chance to not only earn individually price money, but also the possibility of earning price money to or from their tribe. Gladiators fight not only for the glory and for the honor of their tribe but also a chance to be rewarded by their fellow tribe members.`,
  description2:
    "To participate in the Tribe Wars, warriors will have to pay a mandatory entrance fee (which will vary depending on the allocation spots available for the specific Tribe Wars). When registration has closed, gladiators will be put into combat, but the gladiators will only be able to face gladiators from another tribe (until no such other gladiators are available).",
  description3: `The price pool and the distribution of prizes in Tribe Wars will be based on the participation fee.
    
    The entire price pool will be distributed equally amongst all gladiators who participated in the Tribe Wars and belonging to the winning tribe. That means that if your tribe won the Tribe wars, yet you lost your individual battle, you will still be equally rewarded.
    
    A clever tribe member understands that there is significant strength in numbers during the Tribe Wars. An even more clever tribe member also understands that not only will you and your tribe become richer if you participate and your tribe wins, but you will also make the other tribes poorer.
    `,
};
const arena3 = {
  id: "degen",
  title: "The Degen Arena",
  logo: "/img/arenas/arena3-logo.png",
  cover: "",
  description: `The Degen Arena is only recommended for the most degen gladiators. In the Degen Arena, contestants will be able to stake their NFT and additional ETH in a battle with another gladiator. The winner takes it all - NFT and staked ETH. Enroll your gladiator or accept a duel to participate in the games.`,
  description2: `Participation in the Degen Arena will be free and gladiators will be only be able to face gladiators within the same bracket of gladiator points as themselves. The available gladiator point brackets will be revealed each week and once a gladiator has enter and been assigned an opponent they will not be able to back out of the fight.
    
    REMEMBER, ONLY PARTICIPATE IF YOU ARE WILLING TO GO FULL DEGEN AND POSSIBLY LOSE YOUR GLADIATOR (NFT).`,
  description3: `The price will be the opponents gladiator (NFT).`,
};

const getArenaById = (id: string) => {
  switch (id) {
    case "deathmatch":
      return arena1;
    case "tribewars":
      return arena2;
    case "degen":
      return arena3;
    default:
      return {
        id: "",
        title: "",
        logo: "",
        cover: "",
        description: "",
        description2: "",
        description3: "",
      };
  }
};

export { arena1, arena2, arena3, getArenaById };
