// import Gladiator from './views/gladiator/Gladiator'
// import About from './views/about/About'
import Start from "./views/start/Start";
import Tribe from "./views/tribe/Tribe";
import Tribes from "./views/tribes/Tribes";
import ArenaView from "./views/arena/ArenaView";
import DegenArena from "./views/arenas/degen_arena/DegenArena";
// import GladiatorNFT from "./views/gladiator/GladiatorNFT";
import Arenas from "./views/arenas/Arenas";
// import NotFound from "./views/error/NotFound";
import Roadmap from "./views/roadmap/Roadmap";
import Team from "./views/team/Team";
import Mint from "./views/mint/Mint";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import { Routes, Route } from "react-router-dom";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { colors, font } from "./config/constants";
import "./admin";

function App() {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        background: colors.background,
        color: colors.text,
        fontFamily: font.family,
        fontSize: font.size.normal,
      },
    })
  );

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar />
      <Routes>
        <Route path="/" element={<Start />} />
        {/* <Route path="/gladiator/:tokenId" element={<Gladiator/>} />*/}
        {/* <Route path="/gladiator/:id" element={<Start />} /> */}
        <Route path="/mint" element={<Mint />} />
        <Route path="/tribe/:name" element={<Tribe />} />
        <Route path="/tribes" element={<Tribes />} />
        <Route path="/arena/:name" element={<ArenaView />} />
        <Route path="/degen-arena" element={<DegenArena />} />
        <Route path="/arenas" element={<Arenas />} />
        <Route path="/roadmap" element={<Roadmap />} />
        <Route path="/team" element={<Team />} />
        <Route path="/*" element={<Start />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
