import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import Faq from '../../../components/faq/Faq'
import { tribesContent } from '../../../content/start'

export default function TribeFaq(){

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: 150,
            marginBottom: 150,
            textAlign: 'center',
        }
    }))

    const classes = useStyles()
    
    return (<>
        <Container className={classes.root}>
            <Grid container justifyContent="center">
                <Grid xs={12} sm={6} md={6} lg={4} xl={4} item={true}>
                    <Faq
                        title={tribesContent.title2}
                        text={tribesContent.text2}
                        image={tribesContent.image2}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={4} item={true}>
                    <Faq
                        title={tribesContent.title1}
                        text={tribesContent.text1}
                        image={tribesContent.image1}
                    />
                </Grid>
                <Grid xs={12} sm={6} md={6} lg={4} xl={4} item={true}>
                    <Faq
                        title={tribesContent.title3}
                        text={tribesContent.text3}
                        image={tribesContent.image3}
                    />
                </Grid>
            </Grid>
        </Container>
        </>
    )
}