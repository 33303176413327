
const startContent = {
    header: `Meta Gladiators is a play-to-earn (P2E) NFT gladiator game based on an elaborate game system, offering opportunities for the holders of the NFT to win and earn ether, NFTs (including blue chips), WL spots and much more by participating in the gladiator games.

    Participants will be able to stake their gladiators in the PVP Deathmatch Arena, contribute to Tribe Wars and for the bravest souls, there will also be a chance to risk it all in the Degen Arena, where NFT owners will battle with their NFT as the stake.`
}

const arenasContent = {
    text: `Meta Gladiators will initially offer three arenas for gladiators to participate in.
    
    Meta Gladiators will also offer anyone above the age of 18 years, holder or non-holder, the possibility to bet on most gladiator games. Betting will be offered in ether and odds will be set a fair value market and calculated by Meta Gladiators' in house gambling system.`,
    arena1title: 'The Deathmatch Arena',
    arena2title: 'The Tribe Wars',
    arena3title: 'The Degen Arena',
    arena1text: `The Deathmatch Arena is a combat arena available for contestants on a weekly basis. Every week the arena will open for registration for gladiators who are willing to battle for a chance to win amazing prizes and - maybe even more importantly - precious experience points.`,
    arena2text: `Tribe Wars is a unique concept giving all gladiators a monthly chance to not only earn individually price money, but also the possibility of earning price money to or from their tribe. Thus, in Tribe Wars the gladiators do not only fight for individual honor and glory, but they also fight for the honor of their tribe and a chance to reward or be rewarded by their fellow tribe members.`,
    arena3text: `The Degen Arena is an arena only recommended for the most degen gladiators. In the Degen Arena, contestants will be able to stake and battle another gladiator within the same bracket of Gladiator point with the chance of winning the opponents NFT or losing their own NFT.`
}

const tribesContent = {
    title: 'Meet The Gladiators',
    text: `The NFT collection will consist of 5,000 gladiators, divided into four tribes. Upon mint, people will receive a NFT from one of the four tribes below. All four tribes will respectively have their own unique attributes that will grant them certain base tribe points, which will be used and help them gain advantages upon battle.`,
    title1: 'Tribe points',
    title2: 'Items',
    title3: 'Gladiator points',
    text1: `All four tribes will each respectively have their own unique attributes that will grant them certain tribe points. Gladiators will receive tribe points based on their (i) attack (ii) defense, (iii) speed and (iv) individual items received upon mint.`,
    text2: `Each tribe will have their unique set of items, in accordance with their background and skill set. The items will be subject to rarity and the more rare an item is, the more tribe points it will grant the NFT, making it stronger and hence more likely to win once in battle.`,
    text3: `Tribe points and experience points are added together to create Gladiator points. The more Gladiator points a gladiator (the NFT) has, the stronger it is and hence more likely to find success in the gladiator games.`,
    image1: '/img/icons/tribepoints.png',
    image2: '/img/pandas/weapons/pandas-molotov-cocktail.png',
    image3: '/img/icons/gladiatorpoints.png',
}

export {
    startContent,
    arenasContent,
    tribesContent
}