import { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Grid from "@material-ui/core/Grid";
import Button from "@mui/material/Button";

interface ISelectGladiator {
  ownerTokens: Array<string>;
  handleClick: (tokenId: string) => void;
}

export default function SelectGladiator({
  ownerTokens,
  handleClick,
}: ISelectGladiator) {
  const [open, setOpen] = useState(false);

  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textAlign: "center",
      },
      modal: {
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(0,0,0,0.9)",
        zIndex: 1000,
        width: "100%",
      },
    })
  );
  const classes = useStyles();

  const Title = () => <h2>Available gladiators</h2>;

  const GladiatorCards = () => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          paddingTop: 40,
          marginBottom: 40,
          textAlign: "center",
          width: "100%",
        },
      })
    );
    const classes = useStyles();
    return (
      <Grid container className={classes.root} justifyContent={"center"}>
        <Grid item>
          <Grid container justifyContent={"center"} spacing={10}>
            {ownerTokens.map((item, _) => (
              <GladiatorCard key={item} tokenId={item} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const GladiatorCard = ({ tokenId }: { tokenId: string }) => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          width: 250,
          // marginTop: 10,
          marginBottom: 10,
          marginLeft: xs ? 0 : 15,
          borderRadius: 10,
          cursor: "pointer",
        },
      })
    );
    const classes = useStyles();
    return (
      <img
        onClick={() => handleClick(tokenId)}
        className={classes.root}
        src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${tokenId}.png`}
        alt=""
      />
    );
  };

  return (
    <div className={classes.root}>
      <p>
        Enroll one of your gladiators to the arena and wait for an opponent to
        accept your duel.
      </p>
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          setOpen(true);
        }}
      >
        Enroll gladiator
      </Button>
      <div
        className={classes.modal}
        onClick={() => setOpen(false)}
        style={{ display: open ? "block" : "none" }}
      >
        <Title />
        <GladiatorCards />
      </div>
    </div>
  );
}
