import { Fragment } from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { makeStyles, createStyles, styled } from "@material-ui/core/styles";
import { getTribeById } from "../../../config/tribes";
import { font } from "../../../config/constants";

type TribeItemsProps = {
  id: string;
};

type CategoryProps = {
  title: string;
  data: Array<{
    title: string;
    description: string;
    image: string;
    value: number;
    rarity: number;
    type: string;
  }>;
};

type ItemProps = {
  title: string;
  description: string;
  image: string;
  value: number;
  rarity: number;
  type: string;
};

export default function TribeItems({ id }: TribeItemsProps) {
  const tribe = getTribeById(id);

  // const xs = useMediaQuery('(max-width:600px) and (min-width:0px)')
  // const sm = useMediaQuery('(max-width:960px) and (min-width:600px)')

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        background: "linear-gradient(80deg, #252728 0%, #101415 100%)",
        borderTop: "2px solid #282828",
        borderBottom: "2px solid #2c2e2e",
        boxShadow: "0px 0px 8px #000",
        width: "100%",
        height: "fit-content",
      },
      categoryGrid: {
        height: "fit-content",
      },
      category: {},
      item: {
        height: 100,
        width: 100,
        float: "left",
        marginRight: 20,
        marginBottom: 50,
        background: "rgba(255, 255, 255, 0.1)",
        borderRadius: 16,
        boxShadow: "0 4px 30px rgba(0, 0, 0, 0.1)",
        backdropFilter: "blur(5px)",
        webkitBackdropFilter: "blur(5px)",
        border: "1px solid rgba(255, 255, 255, 0.2)",
        "&:hover, &:focus": {
          transform: "scale(1.1)",
          transition: "transform .2s",
        },
      },
      tooltipImage: {
        height: 300,
      },
      tooltipTitle: {
        color: "white",
        fontWeight: "bold",
        fontSize: font.size.large,
        fontFamily: font.family,
      },
      tooltipDescription: {
        color: "white",
        marginTop: 10,
        fontSize: 16,
        fontFamily: font.family,
      },
      tooltipRow: {},
      tooltipRowOne: {
        fontFamily: font.family,
        fontSize: font.size.normal,
        color: "gray",
        width: "30%",
        float: "left",
      },
      tooltipRowTwo: {
        fontFamily: font.family,
        fontSize: font.size.normal,
        width: "70%",
      },
    })
  );

  const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "black",
      border: "1px solid rgba(255, 255, 255, 0.2)",
      color: "white",
      borderRadius: 25,
      padding: 20,
    },
  }));

  const classes = useStyles();

  const Category = ({ title, data }: CategoryProps) => {
    return (
      <div className={classes.category}>
        <h3>{title}</h3>
        {data.map((item, index) => {
          return <Item key={index} {...item} />;
        })}
      </div>
    );
  };

  const Item = ({
    title,
    image,
    description,
    value,
    rarity,
    type,
  }: ItemProps) => {
    const isHidden = false; //type !== "attack";
    const typeFirstCapitalized =
      type[0].toUpperCase() + type.slice(1).toLowerCase();
    // const improvedTitle = value > 0 ? typeFirstCapitalized + ' +' + value : title
    // const improvedDescription = value > 0 ? title+' will increase your '+type+' by +'+value+' points' : description
    return (
      <>
        <HtmlTooltip
          placement="top"
          title={
            <Fragment>
              <img
                className={classes.tooltipImage}
                alt={""}
                src={`/img/${isHidden ? "icons/lock.png" : image}`}
              />
              <div className={classes.tooltipTitle}>
                {isHidden ? "Unrevealed" : title}
              </div>
              {/* <div className={classes.tooltipDescription}>{improvedDescription}</div> */}
              <div className={classes.tooltipRow}>
                {value > 0 && (
                  <>
                    <div className={classes.tooltipRowOne}>
                      {typeFirstCapitalized}
                    </div>
                    <div className={classes.tooltipRowTwo}>{"+" + value}</div>
                  </>
                )}
              </div>
              <div className={classes.tooltipRow}>
                {/* <div className={classes.tooltipRowOne}>
                                    Rarity
                                </div>
                                <div className={classes.tooltipRowTwo}>
                                    {' '+rarity}
                                </div> */}
              </div>
            </Fragment>
          }
        >
          <img
            className={classes.item}
            alt={""}
            src={`/img/${isHidden ? "icons/lock.png" : image}`}
          />
        </HtmlTooltip>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Container>
        <Grid container>
          <Grid
            className={classes.categoryGrid}
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            item={true}
          >
            <Category
              title="Tribe items"
              data={tribe.items.sort((a, b) => a.value - b.value).reverse()}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
