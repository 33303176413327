import Container from '@material-ui/core/Container';
import TeamExtensiveInfo from './components/TribeExtensiveInfo'
import TribeItems from './components/TribeItems'
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { getLastUrlPath } from '../../hooks/utilityHooks'
import { getTribeById } from '../../config/tribes'

export default function Tribe(){
    
    const tribeId = getLastUrlPath()
    const tribe = getTribeById(tribeId)

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            
        },
        top: {
            backgroundImage: `url("/img/${tribe.backgroundImage}")`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
        },
    }))

    const Spacing = ({ value = 50 } : { value?: number }) => <div style={{height: value}}></div>

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <div className={classes.top}>
                <Container>
                    <Spacing/>
                    <TeamExtensiveInfo id={tribeId}/>
                </Container>
            </div>
            <Spacing value={30}/>
            <TribeItems id={tribeId}/>
            <Spacing value={100}/>
        </div>
    )
}