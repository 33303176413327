import hogs from "./tribes/hogs";
import pandas from "./tribes/pandas";
import hyenas from "./tribes/hyenas";
import mandrills from "./tribes/mandrills";

const tribes = {
  hogs: hogs,
  pandas: pandas,
  hyenas: hyenas,
  mandrills: mandrills,
};

const getTribeById = (id: string) => {
  switch (id) {
    case "pandas":
      return tribes.pandas;
    case "hyenas":
      return tribes.hyenas;
    case "hogs":
      return hogs;
    case "mandrills":
      return tribes.mandrills;
    default:
      return {
        id: "",
        title: "",
        description1: "",
        description2: "",
        image1: "",
        image2: "",
        backgroundImage: "",
        unrevealed: false,
        properties: {
          attack: 0,
          defense: 0,
          speed: 0,
        },
        items: [
          {
            title: "",
            description: "",
            value: 0,
            rarity: 0,
            image: "",
            type: "",
          },
        ],
      };
  }
};

export { tribes, getTribeById };
