import HeaderSection from "./components/HeaderSection";
import TribesSection from "./components/TribesSection";
// import ArenaSection from "./components/ArenaSection";
import { makeStyles, createStyles } from "@material-ui/core/styles";

export default function Start() {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        background: "linear-gradient(45deg, #171717 0%, #000000 70%)",
      },
    })
  );

  const classes = useStyles();
  const Spacing = ({ value = 100 }: { value?: number }) => (
    <div style={{ height: value }}></div>
  );

  return (
    <div className={classes.root}>
      <HeaderSection />
      <TribesSection />
      {/* <ArenaSection/> */}
      <Spacing value={30} />
    </div>
  );
}
