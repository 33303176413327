
import Button from '@mui/material/Button'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { switchNetwork } from '../../../services/Web3'

export default function SwitchNetwork() {

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                marginTop: 30
            }
        }))

    const classes = useStyles()

    return (
        <div className={classes.root}>
            <Button
                color="error"
                variant="outlined"
                onClick={() => 
                    switchNetwork()
                }>Switch network</Button>
        </div>
    )

}