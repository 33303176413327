import { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DegenArena from "../../../../services/DegenArena";
import { useNavigate } from "react-router-dom";
import Gladiator from "../../../../services/Gladiator";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import SelectGladiator from "./SelectGladiator";
import { links } from "../../../../config/constants";

interface ICreateDuel {
  ownerTokens: Array<string>;
}

export default function CreateDuel({ ownerTokens }: ICreateDuel) {
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");

  const [selectedGladiator, setSelectedGladiator] = useState<string>();
  const [stakedEth, setStakedEth] = useState("0");
  const [isApproved, setApproved] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textAlign: "center",
        marginTop: 40,
      },
      button: {
        marginTop: 20,
      },
    })
  );
  const classes = useStyles();
  const navigate = useNavigate();

  const StakeEthForm = () => {
    const StakedEthTextField = styled(TextField)({
      width: 100,
      marginLeft: 15,
      marginRight: 10,
      "& input:valid + fieldset": {
        borderColor: "#9c27b0",
        borderWidth: 0.5,
      },
      "& .MuiFormLabel-root": {
        color: "#9c27b0",
      },
      "& .MuiInputBase-input": {
        height: 20,
        color: "white",
      },
    });

    return (
      <StakedEthTextField
        id="outlined-basic"
        size="small"
        color="secondary"
        label="Stake eth"
        value={stakedEth}
        variant="outlined"
        autoFocus
        onChange={(e) => {
          const _valid = e.target.value.match(/^[0-9]*\.?[0-9]*$/) != null;
          setStakedEth(_valid ? e.target.value : "");
        }}
      />
    );
  };

  const handleClickCreateDuel = async () => {
    setLoading(true);
    const response = await DegenArena.createDuel(selectedGladiator!, stakedEth);
    console.log(response);
    setLoading(false);
    if (response.transactionHash) {
      window.location.reload();
    }
  };

  const handleClickApprove = async () => {
    setLoading(true);
    const response = await Gladiator.approveDegenArena();
    setLoading(false);
    if (response.transactionHash) {
      setApproved(await Gladiator.isDegenArenaApproved());
    }
  };

  const Approved = () => {
    return (
      <div className={classes.button}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <StakeEthForm />
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleClickCreateDuel()}
            >
              Create duel
            </Button>
            <p>⚠️ This will transfer your gladiator to Degen Arena!</p>
            <p>
              You will not be able to reclaim it if someone accepts your duel
            </p>
          </>
        )}
      </div>
    );
  };

  const NotApproved = () => {
    return (
      <div className={classes.button}>
        {isLoading ? (
          <Loading />
        ) : (
          <>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleClickApprove()}
            >
              Approve Degen Arena
            </Button>
            <p>
              You will need to approve Degen Arena to handle your Gladiator NFT
            </p>
          </>
        )}
      </div>
    );
  };

  const StepOne = () => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          textAlign: "center",
        },
        image: {
          width: 250,
          marginTop: 40,
          marginLeft: xs ? 0 : 15,
          borderRadius: 10,
          cursor: "pointer",
        },
      })
    );
    const classes = useStyles();
    return (
      <div className={classes.root}>
        <SelectGladiator
          ownerTokens={ownerTokens}
          handleClick={async (tokenId) => {
            setApproved(await Gladiator.isDegenArenaApproved());
            setSelectedGladiator(tokenId);
          }}
        />
        {selectedGladiator && (
          <img
            onClick={() =>
              window.open(links.openSeaToken + selectedGladiator, "_blank")
            }
            className={classes.image}
            src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${selectedGladiator}.png`}
            alt=""
          />
        )}
      </div>
    );
  };

  const StepTwo = () => {
    if (!selectedGladiator) return null;
    return isApproved ? <Approved /> : <NotApproved />;
  };

  const Loading = () => <CircularProgress color="secondary" />;

  const NotHolder = () => {
    return (
      <>
        <Button
          color="secondary"
          variant="outlined"
          onClick={() => navigate(`/mint`)}
        >
          Mint
        </Button>
        <p>
          ⚠️ You need a Gladiator NFT to play and you are not holding any yet!{" "}
        </p>
      </>
    );
  };

  return ownerTokens.length > 0 ? (
    <div className={classes.root}>
      <StepOne />
      <StepTwo />
    </div>
  ) : (
    <NotHolder />
  );
}
