
import TribeProperty from './TribeProperty'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useNavigate } from 'react-router-dom';
import { getTribeById } from '../../config/tribes'

type TribeGladiatorProps = {
    title: string
    image?: string
    id: string
}

export default function TribeGladiator({ title, image, id }: TribeGladiatorProps){

    const tribe = getTribeById(id)

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)')
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)')

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: 420,
            margin: 10,
            cursor: tribe.unrevealed ? 'pointer' : 'normal',
            marginTop: xs || sm ? 100 : 0
        },
        img: {
            height: 350
        },
        description: {
            textAlign: 'center',
            marginRight: 20,
            marginLeft: 20,
            marginBottom: 30
        },
        tribeProperty: {
            marginBottom: 10
        }
    }))

    const classes = useStyles()
    const navigate = useNavigate()

    const Properties = () => {
        return (
            <>
            <div className={classes.tribeProperty}>
            <TribeProperty
                title={'Attack'}
                value={tribe.properties.attack}
                icon={'attack'}/>
            </div>
            <div className={classes.tribeProperty}>
                <TribeProperty
                    title={'Defense'}
                    value={tribe.properties.defense}
                    icon={'defense'}/>
            </div>
            <div className={classes.tribeProperty}>
                <TribeProperty
                    title={'Speed'}
                    value={tribe.properties.speed}
                    icon={'speed'}/>
            </div>
            </>
        )
    }
    
    return (
        <div 
            className={classes.root}
            onClick={()=> !tribe.unrevealed ? null : navigate(`/tribe/${id}`)}>
            <h3>{title}</h3>
            <img alt="gladiator" className={classes.img} src={`/img/${image ?? 'empty-gladiator.png'}`}/>
            {image && <Properties/>}
        </div>
    )
}