import axios from "axios";
import { getWeb3, getAccount } from "./Web3";
import {
  gladiatorContractAddress,
  degenArenaContractAddress,
} from "../config/constants";
import { getProof } from "./mintService";
const json = require("../contracts/Gladiator.json");

export default class Gladiator {
  static async getContract() {
    const web3 = await getWeb3();
    return new web3.eth.Contract(json.abi, gladiatorContractAddress);
  }

  static async mint(amount: number, isFreeMint = false) {
    if (isFreeMint) {
      return Gladiator._mintFree(amount);
    }
    return Gladiator._mint(amount);
  }

  static async _mint(amount: number) {
    const web3 = await getWeb3();
    const gladiator = await Gladiator.getContract();
    const mintPrice = await gladiator.methods.mintPrice().call();
    const account = await getAccount();
    const price = web3.utils.toBN(mintPrice) * amount;
    const proof = getProof(account);
    try {
      return await gladiator.methods.mint(amount, proof).send({
        from: account,
        value: price,
      });
    } catch (error: any) {
      return error;
    }
  }

  static async _mintFree(amount: number) {
    try {
      const gladiator = await Gladiator.getContract();
      const account = await getAccount();
      return await gladiator.methods.mintFree(amount).send({
        from: account,
      });
    } catch (error: any) {
      if (
        error.message &&
        error.message.includes("User denied transaction signature.")
      ) {
        return { error: "USER_REJECTED" };
      }
      console.error(error);
    }
  }

  static async mintsLeft() {
    const gladiator = await Gladiator.getContract();
    const account = await getAccount();
    return await gladiator.methods.addressToMints(account).call();
  }

  static async getBalance() {
    const gladiator = await Gladiator.getContract();
    const account = await getAccount();
    return await gladiator.methods.balanceOf(account).call();
  }

  static async getTotalSupply() {
    const gladiator = await Gladiator.getContract();
    return parseInt((await gladiator.methods.totalSupply().call()) ?? 0);
  }

  static async isMintOpen() {
    const gladiator = await Gladiator.getContract();
    const response = await gladiator.methods.isMintOpen().call();
    return response;
  }

  static async isPublicMintOpen() {
    const gladiator = await Gladiator.getContract();
    const response = await gladiator.methods.isWhitelistOnly().call();
    return !response;
  }

  static async getNumberOfFreeMints() {
    const gladiator = await Gladiator.getContract();
    const account = await getAccount();
    const response = await gladiator.methods.addressToFreeMints(account).call();
    return parseInt(response) ?? 0;
  }

  static async getMintLimit() {
    const gladiator = await Gladiator.getContract();
    const response = await gladiator.methods.mintTotalLimit().call();
    return parseInt(response);
  }

  static async getTokensByOwner(address?: string) {
    const gladiator = await Gladiator.getContract();
    const account = address ? address : await getAccount();
    return await gladiator.methods.tokensByOwner(account).call();
  }

  static async checkWhitelist() {
    const gladiator = await Gladiator.getContract();
    const account = await getAccount();
    const proof = getProof(account);
    try {
      const response = await gladiator.methods.checkWhitelist(proof).call({
        from: account,
      });
      return response;
    } catch (error: any) {
      return false;
    }
  }

  static async isDegenArenaApproved() {
    const gladiator = await Gladiator.getContract();
    const account = await getAccount();
    return await gladiator.methods
      .isApprovedForAll(account, degenArenaContractAddress)
      .call();
  }

  static async approveDegenArena() {
    const gladiator = await Gladiator.getContract();
    const account = await getAccount();
    try {
      return await gladiator.methods
        .setApprovalForAll(degenArenaContractAddress, true)
        .send({ from: account });
    } catch (error: any) {
      return false;
    }
  }

  static async getMetadata(id: string) {
    const gladiator = await Gladiator.getContract();
    const ipfs = await gladiator.methods.tokenURI(id).call();
    const url = `https://ipfs.io/ipfs/${ipfs.replace("ipfs://", "")}`;
    const response = await axios(url);
    return response.data;
  }
}
