import { useState, useEffect } from "react";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import NavbarMenu from "./NavbarMenu";
import { SmallIcondButton, SmallTwitterButton } from "../ui/buttons";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { menu, links } from "../../config/constants";
import { log } from "../../admin";

type ButtonType = {
  url: string;
  title: string;
};

export default function Navbar() {
  const [opacity, setOpacity] = useState(1);
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");
  const sm = useMediaQuery("(max-width:960px) and (min-width:600px)");

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        backgroundImage: "linear-gradient(black, transparent)",
        position: "fixed",
        height: 40,
        top: 0,
        width: "100%",
        paddingTop: 10,
        textAlign: "right",
        opacity: opacity,
        display: opacity < 0 ? "none" : "true",
      },
      left: {
        float: "left",
        marginLeft: 30,
      },
      right: {},
    })
  );

  const classes = useStyles();
  const navigate = useNavigate();

  const Button = ({ title, url }: ButtonType) => {
    const useStyles = makeStyles(() =>
      createStyles({
        root: {
          background: "transparent",
          height: 40,
          paddingTop: 10,
          color: "white",
          cursor: "pointer",
          fontSize: 24,
          float: "right",
          fontWeight: "bold",
          marginRight: xs ? 20 : 40,
          "&:hover, &:focus": {
            transform: "scale(1.1)",
            transition: "transform .2s",
          },
        },
      })
    );
    const classes = useStyles();

    return (
      <div
        className={classes.root}
        onClick={() => {
          log.clicked(url);
          navigate(`/${url}`);
        }}
      >
        {title}
      </div>
    );
  };

  useEffect(() => {
    window.addEventListener(
      "scroll",
      () => {
        setOpacity(Math.floor((1 - window.scrollY / 70) * 100) / 100);
      },
      { passive: true }
    );
  }, []);

  return (
    <div className={classes.root}>
      {xs || sm ? (
        <NavbarMenu />
      ) : (
        <div>
          <div className={classes.left}>
            {/* <SmallIcondButton
              image="discord"
              handlePress={() => window.open(links.discord, "_blank")}
            /> */}
            {/* &nbsp; &nbsp; &nbsp; */}
            <SmallIcondButton
              image="opensea"
              handlePress={() => window.open(links.opensea, "_blank")}
            />
            &nbsp; &nbsp; &nbsp;
            <SmallTwitterButton />
          </div>
          <div className={classes.right}>
            {menu.map((m, index) => (
              <Button key={index} url={m.url} title={m.title} />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
