import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery';
import TribeGladiator from '../../../components/tribe/TribeGladiator'
import TribeFaq from './TribeFaq'
import { tribesContent } from '../../../content/start'
import { tribes } from '../../../config/tribes'

type TitleProps = {
    text: string
}

type DescriptionProps = {
    text: string
}

type TribeGridProps = {
    title: string
    id: string
    image?: string
}

export default function TribesSection(){

    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)')
    // const sm = useMediaQuery('(max-width:960px) and (min-width:600px)')

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            marginTop: 100,
            textAlign: 'center'
        },
        title: {
            marginTop: 40
        },
        description: {
            marginRight: xs ? 0 : 60,
            marginLeft: xs ? 0 : 60,
            marginBottom: 50,
            whiteSpace: 'pre-line',
        },
        tribe: {
            marginBottom: 100
        },
        gladiatorCard: {
            marginRight: 5,
            marginLeft: 5
        }
    }))

    const classes = useStyles()

    const Title = ({ text }: TitleProps) => {
        return(
            <h2 className={classes.title}>{text}</h2>
        )
    }

    const Description = ({ text }: DescriptionProps) => {
        return(
            <p className={classes.description}>{text}</p>
        )
    }

    const TribeGrid = ({ title, id, image }: TribeGridProps) => {
        return(
            <Grid className={classes.tribe} xs={12} sm={6} md={4} lg={3} xl={3} item={true}>
                   <TribeGladiator 
                        title={title} 
                        id={id}
                        image={image}/>
            </Grid>
        )
    }

    
    return (<>
        <Container className={classes.root}>
            <Grid container justifyContent="center">
                <Grid xs={12} sm={12} md={12} lg={12} xl={12} item={true}>
                    <Title text={tribesContent.title}/>
                    <Description text={tribesContent.text}/>
                </Grid>
               <TribeGrid title={tribes.pandas.title} id={tribes.pandas.id} image={tribes.pandas.image1}/>
               <TribeGrid title={tribes.hyenas.title} id={tribes.hyenas.id} image={tribes.hyenas.image1}/>
               <TribeGrid title={tribes.hogs.title} id={tribes.hogs.id} image={tribes.hogs.image1}/>
               <TribeGrid title={tribes.mandrills.title} id={tribes.mandrills.id} image={tribes.mandrills.image1}/>
            </Grid>
            <TribeFaq/>
        </Container>
        </>
    )
}