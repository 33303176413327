const pandas = {
  id: "pandas",
  title: "Guerilla Pandas",
  description1: `Meet the Guerilla Pandas. From the deep mountains of southwest China, these pandas united under the banner of "Magna Melanoleuca" to push back intruders pillaging their communities. 
        
        After years of guerilla warfare against ruthless bamboo seeking intruders, the Guerilla Pandas finally managed to drive out the intruders from their home forest. However, all those years of bloody battles developed a clench for combat and blood. An urge the Guerilla Pandas are now looking to satisfy.`,
  description2: `Meet the Guerilla Pandas - on the verge of extinction after decades of pillaging by bamboo seekers in the deep mountains of southwest China, the last remaining panda tribes decided to join forces and fight back against their ruthless intruders.

        The panda tribes united and mobilized an army of panda warriors with the sole purpose of pushing back the intruders who were pillaging their homes in the forest, stealing their precious bamboo and destroying anyone who stood in their way.
        
        United under the banner of "Magna Melanoleuca" the army of pandas valiantly started to fight back the intruders. Even if the pandas were outnumbered and outgunned, the pandas realized that they knew their forest better than their intruders and the resistance quickly turned into a gerilla warfare.
        
        After almost a decade of warfare with many casualties on both sides, the intruders finally have had enough - the Magna Melanoleuca had successfully fought back and secured the victory of their forest. However, the victory was bittersweet for many of the pandas. The many years of gerilla warfare had changed the Magna Melanoleuca warriors by their core. Almost all of the Magna Melanoleuca warriors had lost their peaceful demeanor and they had difficulties adjusting to the quiet life in the forest.
        
        Years of having to feast on flesh had made bamboo not taste as good as it used to. What had once started as a desire for peace and quiet in their homes, was no longer alluding to the battle scarred pandas of the Magna Melanoleuca. Instead the warriors now had a clench for combat and blood. An urge they are now looking to satisfy.`,
  image1: "pandas/panda1-background1.png",
  image2: "pandas/panda2.png",
  backgroundImage: "pandas/background-pandas.png",
  unrevealed: true,
  properties: {
    attack: 50,
    defense: 60,
    speed: 30,
  },
  items: [
    {
      title: "Bracelet",
      attribute: "Bracelet",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 5,
      image: "pandas/items/pandas-bracelet.png",
      type: "item",
    },
    {
      title: "Cargo Shorts",
      attribute: "Cargo_shorts",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 30,
      image: "pandas/items/pandas-cargo-shorts.png",
      type: "item",
    },
    {
      title: "Jungle Pants",
      attribute: "Jungle_pants",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 30,
      image: "pandas/items/pandas-jungle-pants.png",
      type: "item",
    },
    {
      title: "Aviator Glasses",
      attribute: "Aviator_glasses",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-aviatorglasses.png",
      type: "item",
    },
    {
      title: "Ear Ring",
      attribute: "Ear_ring",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 5,
      image: "pandas/items/pandas-ear-ring.png",
      type: "item",
    },
    {
      title: "Farmers hat",
      attribute: "Farmers_hat",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 45,
      image: "pandas/items/pandas-farmershat.png",
      type: "item",
    },
    {
      title: "Rambo Bandana",
      attribute: "Rambo_bandana",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-rambo-bandana.png",
      type: "item",
    },
    {
      title: "Red Beret",
      attribute: "Red_beret",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-red-beret.png",
      type: "item",
    },
    {
      title: "Shemag Scarf",
      attribute: "Shemagh",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-shemagh.png",
      type: "item",
    },
    {
      title: "Secondary Axe",
      attribute: "Secondary_tomahawk_axe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 8,
      image: "pandas/weapons/pandas-double-axe.png",
      type: "attack",
    },
    {
      title: "Field Jacket",
      attribute: "Field_jacket",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-field-jacket.png",
      type: "item",
    },
    {
      title: "Rope",
      attribute: "Rope",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-rope.png",
      type: "item",
    },
    {
      title: "T-shirt",
      attribute: "Tshirt",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 30,
      image: "pandas/items/pandas-tshirt.png",
      type: "item",
    },
    {
      title: "Bamboo Spear",
      attribute: "Bamboo_spear",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 55,
      rarity: 1,
      image: "pandas/weapons/pandas-bamboo-spear.png",
      type: "attack",
    },
    {
      title: "Bowie Knife",
      attribute: "Bowie_knife",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 15,
      image: "pandas/weapons/pandas-bowieknife.png",
      type: "attack",
    },
    {
      title: "Compound Bow",
      attribute: "Compound_bow",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 80,
      rarity: 3,
      image: "pandas/weapons/pandas-compound-bow.png",
      type: "attack",
    },
    {
      title: "Dagger",
      attribute: "Dagger",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 40,
      rarity: 5,
      image: "pandas/weapons/pandas-dagger.png",
      type: "attack",
    },
    {
      title: "Gurkha Knife",
      attribute: "Gurkha_knife",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 20,
      image: "pandas/weapons/pandas-gurkha-knife.png",
      type: "attack",
    },
    {
      title: "Molotov Cocktail",
      attribute: "Molotov_cocktail",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 70,
      rarity: 5,
      image: "pandas/weapons/pandas-molotov-cocktail.png",
      type: "attack",
    },
    {
      title: "Slingshot",
      attribute: "Slingshot",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 10,
      rarity: 5,
      image: "pandas/weapons/pandas-slingshot.png",
      type: "attack",
    },
    {
      title: "Small Bow",
      attribute: "Small_bow",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 4,
      image: "pandas/weapons/pandas-small-bow.png",
      type: "attack",
    },
    {
      title: "Spas 12",
      attribute: "Spas12",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 100,
      rarity: 2,
      image: "pandas/weapons/pandas-spas-12.png",
      type: "attack",
    },
    {
      title: "Tomahawk Axe",
      attribute: "Tomahawk_axe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 11,
      image: "pandas/weapons/pandas-tomahawk.png",
      type: "attack",
    },
    {
      title: "Venom Snake",
      attribute: "Venom_snake",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 70,
      rarity: 7,
      image: "pandas/weapons/pandas-snake.png",
      type: "attack",
    },
    {
      title: "Tooth Necklace",
      attribute: "Tooth_necklace",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "pandas/items/pandas-toothnecklace.png",
      type: "item",
    },
  ],
};

export default pandas;
