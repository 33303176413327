const hyenas = {
  id: "hyenas",
  title: "Street Hyenas",
  description1: `Born and raised in the slum areas of outskirt Nairobi, these Street Hyenas have seen it all from an early age. Fights, stabbings, and shootings are all part of the daily life for a young hyena growing up in the toughest ghettos of Nairobi. 
        
        Now, with hopes of making riches beyond their wildest imagination, the Street Hyenas are willing to risk it all in the gladiator games. To quote the leader of the pack: “those who have nothing to lose have nothing to fear”.`,
  description2: `Born and raised in the slum areas of outskirt Nairobi, the Street Hyenas have seen it all from an early age. Fights, stabbings and shootings are all part of the daily life for a young hyena growing up in the toughest ghettos of Nairobi, and though ghettos create though hyenas.

        The hyenas come from a place where the rule is shoot first and ask questions later. Raised by the streets they have never been given anything for free. On the contrary they have had to fight for everything they need and even the smallest sign of weakness could be an immediate death sentence in their neighborhoods.
        
        After years of fighting and being exposed to violence, that is all these hyenas know. The disregard for respect of life can be seen in their eyes if one looks closely and anyone who crosses these hyenas’ path better be prepared to face the consequences.
        
        This pack of street soldiers have decided that years of committing crime to survive the day-to-day life no longer is enough. Coming from the streets they understand the importance of strength in numbers and after gathering a sizeable army of fighters, they are now ready to take their street knowledge directly into the arena. With hopes of making riches beyond their wildest imagination, the street hyenas are willing to risk it all. To quote the leader of the pack: “those who have nothing to lose have nothing to fear”.
        
        The Street Hyenas are locked and loaded. We pity anyone who stands in their way.`,
  image1: "hyenas/hyena1-background1.png",
  image2: "hyenas/hyena2.png",
  backgroundImage: "hyenas/background-hyenas.png",
  unrevealed: true,
  properties: {
    attack: 30,
    defense: 30,
    speed: 70,
  },
  items: [
    {
      title: "Rollie Gold",
      attribute: "Rollie_gold",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 5,
      image: "hyenas/items/hyenas-rollie-gold.png",
      type: "item",
    },
    {
      title: "Camo Pants",
      attribute: "Camo_pants",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 50,
      image: "hyenas/items/hyenas-camo-pants.png",
      type: "item",
    },
    {
      title: "Gray Pants",
      attribute: "Gray_pants",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 20,
      image: "hyenas/items/hyenas-gray-pants.png",
      type: "item",
    },
    {
      title: "Bandana",
      attribute: "Bandana",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hyenas/items/hyenas-bandanas.png",
      type: "item",
    },
    {
      title: "Hannibal Mask",
      attribute: "Hannibal_mask",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 5,
      image: "hyenas/items/hyenas-hannibal-mask.png",
      type: "item",
    },
    {
      title: "Mary Jane",
      attribute: "Mary_jane",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 10,
      rarity: 10,
      image: "hyenas/items/hyenas-maryjane.png",
      type: "defense",
    },
    {
      title: "Slippers",
      attribute: "Slippers",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 20,
      image: "hyenas/items/hyenas-slippers.png",
      type: "item",
    },
    {
      title: "Beanie",
      attribute: "Beanie",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 25,
      image: "hyenas/items/hyenas-beanie.png",
      type: "item",
    },
    {
      title: "Eye patch",
      attribute: "Eye_patch",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 25,
      image: "hyenas/items/hyenas-eyepatch.png",
      type: "item",
    },
    {
      title: "AK47 Necklace",
      attribute: "AK47_necklace",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hyenas/items/hyenas-ak47necklace.png",
      type: "item",
    },
    {
      title: "Trashcan Shield",
      attribute: "Trashcan_shield",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 20,
      rarity: 10,
      image: "hyenas/items/hyenas-trashcan-shield.png",
      type: "defense",
    },
    {
      title: "T-shirt",
      attribute: "Tshirt",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hyenas/items/hyenas-tshirt.png",
      type: "item",
    },
    {
      title: "Tank top",
      attribute: "Tank_top",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hyenas/items/hyenas-tanktop.png",
      type: "item",
    },
    {
      title: "Tracksuit",
      attribute: "Tracksuit_blue",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hyenas/items/hyenas-tracksuit-blue.png",
      type: "item",
    },
    // {
    //   title: "Tracksuit Red",
    //   attribute: "Tracksuit_red",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    //   value: 0,
    //   rarity: 10,
    //   image: "hyenas/items/hyenas-tracksuit-red.png",
    //   type: "item",
    // },
    // {
    //   title: "Tracksuit Fluorescence",
    //   attribute: "Tracksuit_fluorescence",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    //   value: 0,
    //   rarity: 10,
    //   image: "hyenas/items/hyenas-tracksuit-fluorescence.png",
    //   type: "item",
    // },
    {
      title: "AK 47",
      attribute: "Ak47",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 100,
      rarity: 2,
      image: "hyenas/weapons/hyenas-ak47.png",
      type: "attack",
    },
    {
      title: "Baseball Bat",
      attribute: "Baseball_bat",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 10,
      image: "hyenas/weapons/hyenas-bat.png",
      type: "attack",
    },
    {
      title: "Brass Knuckles",
      attribute: "Brass_knuckles",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 11,
      image: "hyenas/weapons/hyenas-brass-knuckles.png",
      type: "attack",
    },
    {
      title: "Chain",
      attribute: "Chain",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 5,
      image: "hyenas/weapons/hyenas-chain.png",
      type: "attack",
    },
    {
      title: "Chainsaw",
      attribute: "Chainsaw",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 90,
      rarity: 5,
      image: "hyenas/weapons/hyenas-chainsaw.png",
      type: "attack",
    },
    {
      title: "Farming Hoe",
      attribute: "Farmer_hoe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 70,
      rarity: 10,
      image: "hyenas/weapons/hyenas-farming-hoe.png",
      type: "attack",
    },
    {
      title: "Hook",
      attribute: "Hook",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 20,
      rarity: 3,
      image: "hyenas/weapons/hyenas-hook.png",
      type: "attack",
    },
    {
      title: "Iron Pipe",
      attribute: "Iron_pipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 65,
      rarity: 12,
      image: "hyenas/weapons/hyenas-iron-pipe.png",
      type: "attack",
    },
    {
      title: "Machete",
      attribute: "Machete",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 80,
      rarity: 10,
      image: "hyenas/weapons/hyenas-machete.png",
      type: "attack",
    },
    {
      title: "Rim Cross",
      attribute: "Rim_cross",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 13,
      image: "hyenas/weapons/hyenas-rim-cross.png",
      type: "attack",
    },
    {
      title: "Screwdriver",
      attribute: "Screwdriver",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 55,
      rarity: 12,
      image: "hyenas/weapons/hyenas-screwdriver.png",
      type: "attack",
    },
    {
      title: "Shovel",
      attribute: "Shovel",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 70,
      rarity: 7,
      image: "hyenas/weapons/hyenas-shovel.png",
      type: "attack",
    },
  ],
};
export default hyenas;
