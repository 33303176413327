const hogs = {
  id: "hogs",
  title: "Commando Hogs",
  description1: `The Commando Hogs is an elite military trained group from an unknown Scandinavian location. The group is no longer in military service, but when they were the Commando Hogs were solely used for the most dangerous missions where failure was not an option. The Hogs dangerous reputation precedes them and there are not many of their enemies who live to tell the story of actually encountering them in combat. 

        After several years of serving their country, the Hogs eventually got sick of fighting for a higher purpose and are now offering their services as hired guns.`,
  description2: `The Commando Hogs is an elite military trained group from an unknown Scandinavian location. The group is no longer in military service, but when they were the Commando Hogs were solely used for the most dangerous missions where failure was not an option. The Hogs dangerous reputation precedes them and there are not many of their enemies who live to tell the story of actually encountering them in combat. 

        After several years of serving their country, the Hogs eventually got sick of fighting for a higher purpose. Hence, the Commando Hogs decide to leave the military and instead become freelance warriors, offering their services as hired guns to whoever pays the highest. Even if their service comes as a high price, those who hire them are always satisfied with the outcome. 
        
        This group of mercenaries’ fear nothing and their current motivation is money. After hearing about the gladiator games and seeing the prices available the Commando Hogs mobilized their members and now, they are coming to the gladiator games. 
        
        The well-organized group of killing machines may be outnumbered compared to the other tribes but they are never outgunned. The Command Hogs’ motto ”Pergite” encapsulates what this group is all about, which is forward at all cost. 
        `,
  image1: "hogs/hog1-background.png",
  image2: "hogs/hog2.png",
  backgroundImage: "hogs/background-hogs.png",
  unrevealed: true,
  properties: {
    attack: 60,
    defense: 80,
    speed: 20,
  },
  items: [
    {
      title: "Survival Bracelet",
      attribute: "Survival_bracelet",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hogs/items/hogs-survival-bracelet.png",
      type: "item",
    },
    {
      title: "Camo Pants",
      attribute: "Camo_pants",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 50,
      image: "hogs/items/hogs-camo-pants.png",
      type: "item",
    },
    {
      title: "Green Pants",
      attribute: "Green_pants",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 50,
      image: "hogs/items/hogs-green-pants.png",
      type: "item",
    },
    {
      title: "Eyewear",
      attribute: "M_frames_black",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 20,
      image: "hogs/items/hogs-mframes-black.png",
      type: "item",
    },
    // {
    //   title: "Eyewear Black",
    //   attribute: "M_frames_black",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    //   value: 0,
    //   rarity: 0,
    //   image: "hogs/items/hogs-mframes.png",
    //   type: "item",
    // },
    {
      title: "Boots",
      attribute: "Black_boots",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 10,
      rarity: 20,
      image: "hogs/items/hogs-black-boots.png",
      type: "speed",
    },
    // {
    //   title: "Brown Boots",
    //   attribute: "Brown_boots",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    //   value: 10,
    //   rarity: 20,
    //   image: "hogs/items/hogs-brown-boots.png",
    //   type: "speed",
    // },
    {
      title: "Balaklava",
      attribute: "Balaklava",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 20,
      image: "hogs/items/hogs-balaklava.png",
      type: "item",
    },
    {
      title: "Ballistic Helmet",
      attribute: "Ballistic_helmet",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 10,
      image: "hogs/items/hogs-ballistic-helmet.png",
      type: "defense",
    },
    {
      title: "Ballistic Helmet + Nods",
      attribute: "Ballistic_helmet_nod",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 5,
      image: "hogs/items/hogs-ballistic-helmet-nods.png",
      type: "defense",
    },
    {
      title: "Boonie Hat",
      attribute: "Boonie_hat",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hogs/items/hogs-boonie-hat.png",
      type: "item",
    },
    {
      title: "Commando Beret",
      attribute: "Commando_beret",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 40,
      image: "hogs/items/hogs-green-beret.png",
      type: "item",
    },
    {
      title: "Drill Sergeant Cap",
      attribute: "Drill_sergeant_cap",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 5,
      image: "hogs/items/hogs-drill-sergeant-cap.png",
      type: "item",
    },
    {
      title: "Sauk Beanie",
      attribute: "Sauk_beanie",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hogs/items/hogs-sauk-beanie.png",
      type: "item",
    },
    {
      title: "Dog Tags",
      attribute: "Dog_tags",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 20,
      image: "hogs/items/hogs-dogtag.png",
      type: "item",
    },
    {
      title: "Ammo Belt",
      attribute: "Ammo_belt",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hogs/items/hogs-cartridge-belt.png",
      type: "item",
    },
    {
      title: "Tank Top",
      attribute: "White_tanktop",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hogs/items/hogs-tank-top-white.png",
      type: "item",
    },
    // {
    //   title: "Black Tank Top",
    //   attribute: "Black_tanktop",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    //   value: 0,
    //   rarity: 10,
    //   image: "hogs/items/hogs-tank-top-black.png",
    //   type: "item",
    // },
    // {
    //   title: "Dirty Tank Top",
    //   attribute: "Dirty_tanktop",
    //   description:
    //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
    //   value: 0,
    //   rarity: 10,
    //   image: "hogs/items/hogs-tank-top-dirty.png",
    //   type: "item",
    // },
    {
      title: "Field Jacket",
      attribute: "Field_jacket",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 10,
      image: "hogs/items/hogs-field-jacket.png",
      type: "item",
    },
    {
      title: "Pegasus T-shirt",
      attribute: "Pegasus_t_shirt",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 2,
      image: "hogs/items/hogs-pegasus-tshirt.png",
      type: "item",
    },
    {
      title: "Plate Carrier",
      attribute: "Plate_carrier",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 10,
      image: "hogs/items/hogs-plate-carrier.png",
      type: "defense",
    },
    {
      title: "Boxing Wraps",
      attribute: "Boxing_wraps",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 10,
      rarity: 25,
      image: "hogs/weapons/hogs-boxing-wraps.png",
      type: "attack",
    },
    {
      title: "Breacher",
      attribute: "Breacher",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 100,
      rarity: 2,
      image: "hogs/weapons/hogs-breacher.png",
      type: "attack",
    },
    {
      title: "Cavalry Sabel",
      attribute: "Cavalry_sabre",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 55,
      rarity: 5,
      image: "hogs/weapons/hogs-cavalry-saber.png",
      type: "attack",
    },
    {
      title: "Punch Knife",
      attribute: "Punch_knife",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 30,
      rarity: 15,
      image: "hogs/weapons/hogs-dagger.png",
      type: "attack",
    },
    {
      title: "Knuckle Knife",
      attribute: "Knuckle_knife",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 40,
      rarity: 10,
      image: "hogs/weapons/hogs-knuckle-knife.png",
      type: "attack",
    },
    {
      title: "Pole",
      attribute: "Pole",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 20,
      rarity: 12,
      image: "hogs/weapons/hogs-pole.png",
      type: "attack",
    },
    {
      title: "Sledge Hammer",
      attribute: "Sledgehammer",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 35,
      rarity: 11,
      image: "hogs/weapons/hogs-sledge-hammer.png",
      type: "attack",
    },
    {
      title: "Survival Knife",
      attribute: "Survival_knife",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 8,
      image: "hogs/weapons/hogs-survival-knife.png",
      type: "attack",
    },
    {
      title: "Tactical Axe",
      attribute: "Tactical_axe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 70,
      rarity: 12,
      image: "hogs/weapons/hogs-tactical-axe.png",
      type: "attack",
    },
  ],
};

export default hogs;
