const richard = {
    name: "Rickard aka 0xR",
    bio: "Main developer, security expert and web3 entrepreneur. Founder of cryptocves.com. 0xR is always working, always creating in the crypto space.",
    role: "Engineering",
    image: "/img/team/richard.jpg",
    twitter: "https://twitter.com/0xr000",
    linkedIn: "https://www.linkedin.com/in/rickard-larsson-85302159",
    instagram: ""
}

const elvin = {
    name: "Elvin aka Sp1t",
    bio: "Lawyer and full time crypto and NFT enthusiast. Loves to talk crypto, DeFi, NFTs and why web3 is here to stay. Easy to reach via Discord, Twitter and LinkedIn.",
    role: "Marketing and Community",
    image: "/img/team/elvin.jpg",
    twitter: "https://twitter.com/SwCrypto_Lawyer",
    linkedIn: "https://www.linkedin.com/in/elvinsababi",
    instagram: ""
}

const fredrik = {
    name: "Fredrik aka Frah",
    bio: 'Professional artist who has been "tricked" into hand drawing all gladiators and arenas of Meta Gladiators. Co-founders and an integral part of the team.',
    role: "Artist",
    image: "/img/team/fredrik.jpg",
    twitter: "https://twitter.com/fredrikta2",
    linkedIn: "",
    instagram: "https://www.instagram.com/fredrikta2"
}

export {
    richard,
    elvin,
    fredrik
}