import Container from '@material-ui/core/Container';
import Arena from '../../components/arena/Arena'
import { Spacing } from '../../components/ui/UI'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { getArenaById } from '../../config/arenas'
import { links } from '../../config/constants'
import { getLastUrlPath } from '../../hooks/utilityHooks'

export default function ArenaView(){

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            textAlign: 'center',
            background: 'linear-gradient(45deg, #171717 0%, #000000 70%)',
            whiteSpace: 'pre-line'
        },
        title: { 
        },
        concept: {
            marginTop: -20,
            marginBottom: 40,
            textAlign: 'center',
        },
        price: {
            fontWeight: 'bold',
            marginBottom: -10
        },
        whitepaperLink: {
            fontWeight: 'bold',
            cursor: 'pointer',
            marginTop: 100,
            textDecoration: 'underline'
        }
    }))

    const classes = useStyles()
    const id = getLastUrlPath()
    const arena = getArenaById(id)

    return (
        <div className={classes.root}>
            <Container>
                <Spacing/>
                    <Arena
                        id={arena.id}
                        title={arena.title}
                        description={''}
                        image={arena.logo}
                        />
                <h3>Concept 📝</h3>
                <div className={classes.concept}>
                    {arena.description}
                </div>
                <h3>Participation ⚔️</h3>
                <div className={classes.concept}>
                    {arena.description2}
                </div>
                <h3>Prizes 💰</h3>
                <div className={classes.concept}>
                    {arena.description3}
                </div>
                <p className={classes.whitepaperLink} onClick={()=> window.open(links.gitbookGladiatorPoints, '_blank')}>Learn about the game logic from our whitepaper</p>
                <Spacing/>
            </Container>

        </div>
    )
}

