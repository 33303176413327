import { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { ethers } from "ethers";
import Button from "@mui/material/Button";
import Grid from "@material-ui/core/Grid";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import DegenArena, { IDuel, IDuels } from "../../../../services/DegenArena";
import { getAccount } from "../../../../services/Web3";
import AcceptDuel from "./AcceptDuel";
import { links, font } from "../../../../config/constants";
import { getShortAddress } from "../../../../hooks/utilityHooks";

export default function Duels({
  account,
  ownerTokens,
  title,
  duels,
  all,
}: IDuels) {
  const [selectedDuel, setSelectedDuel] = useState<IDuel>();
  const [filteredDuals, setFilteredDuals] = useState<Array<IDuel>>(duels);
  const [filter, setFilter] = useState<number>(0);
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textAlign: "center",
        marginTop: 40,
      },
      titleContainer: {
        marginTop: 50,
        width: "100%",
        height: xs ? 120 : 80,
        // background: "red",
      },
      title: {
        textAlign: "left",
        width: xs ? "100%" : "40%",
        float: "left",
        fontSize: font.size.xl,
        fontWeight: "bold",
        marginBottom: 10,
        // background: "blue",
      },
      titleFilters: {
        marginTop: 10,
        textAlign: xs ? "left" : "right",
        width: xs ? "100%" : "60%",
        float: xs ? "none" : "left",
      },
      titleFilterButton: {
        color: "white",
        opacity: 0.6,
        marginLeft: xs ? 0 : 20,
        marginRight: xs ? 20 : 0,
        fontSize: xs ? font.size.small : font.size.normal,
        cursor: "pointer",
        "&:hover": {
          fontWeight: "bold",
          transform: "scale(2)",
          marginRight: -3,
          opacity: 1,
        },
        transition: "transform .2s",
      },
      titleFilterButtonSelected: {
        opacity: 1,
        fontSize: xs ? font.size.small : font.size.normal,
        fontWeight: "bold",
        marginLeft: xs ? 0 : 20,
        marginRight: xs ? 20 : 0,
        cursor: "pointer",
      },
      noDuelsText: {
        color: "gray",
        textAlign: "left",
      },
    })
  );
  const classes = useStyles();

  const Duel = (duel: IDuel) => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          float: "left",
          height: 500,
          marginRight: xs ? 0 : 30,
        },
        image1: {
          position: "relative",
          zIndex: duel.winner === duel.tokenId1 ? 1 : 0,
          width: 250,
          height: 350,
          marginTop: 10,
          marginLeft: 30,
          borderRadius: 10,
          cursor: "pointer",
          transform: duel.tokenId2 === "0" ? "none" : "rotate(-5deg)",
          border: "0.5px solid gray",
          opacity:
            duel.winner === duel.tokenId1 || duel.winner === "0" ? 1 : 0.4,
          "&:hover":
            duel.tokenId2 === "0" && duel.owner1 !== account
              ? {
                  transform: "scale(1.01)",
                  boxShadow: "#9c27b0 0px 2px 18px 0px",
                  border: "none",
                }
              : {},
        },
        image2: {
          width: 250,
          height: 350,
          marginTop: 10,
          marginLeft: xs ? -200 : -120,
          borderRadius: 10,
          cursor: "pointer",
          transform: "rotate(5deg)",
          border: " 1px solid gray",
          opacity:
            duel.winner === duel.tokenId2 || duel.winner === "0" ? 1 : 0.4,
        },
        withdrawButton: {
          marginLeft: 30,
        },
        text: {
          marginBottom: -15,
          fontSize: 20,
          fontWeight: "bold",
        },
      })
    );

    const handleClickTokenId1 = async () => {
      const account = await getAccount();
      if (ownerTokens.length === 0)
        return window.open(links.openSeaToken + duel.tokenId1, "_blank");
      if (duel.owner1 !== account && duel.tokenId2 === "0") {
        handleClickDuel(duel);
      } else if (duel.owner1 === account && duel.tokenId2 === "0") {
        window.open(links.openSeaToken + duel.tokenId1, "_blank");
      } else {
        window.open(links.openSeaToken + duel.tokenId1, "_blank");
      }
    };

    const handleClickTokenId2 = async () => {
      window.open(links.openSeaToken + duel.tokenId2, "_blank");
    };

    const handleWithdraw = async () => {
      const response = await DegenArena.withdraw(duel.tokenId1);
      if (response.transactionHash) {
        window.location.reload();
      }
    };

    const classes = useStyles();

    return duel.tokenId1 !== "0" ? (
      <div className={classes.root}>
        <img
          onClick={(e) => {
            e.stopPropagation();
            handleClickTokenId1();
          }}
          className={classes.image1}
          src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${duel.tokenId1}.png`}
          alt=""
        />
        {duel.owner1 === account && duel.tokenId2 === "0" && (
          <div className={classes.withdrawButton}>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => handleWithdraw()}
            >
              Withdraw
            </Button>
          </div>
        )}
        {duel.tokenId2 !== "0" && (
          <img
            onClick={() => handleClickTokenId2()}
            className={classes.image2}
            src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${duel.tokenId2}.png`}
            alt=""
          />
        )}
        <>
          <p className={classes.text}>
            {duel.stake !== "0" && (
              <>💰 Ξ {ethers.utils.formatEther(duel.stake)}</>
            )}
          </p>
          {duel.winner !== "0" && (
            <p className={classes.text}>
              🏆{" "}
              {duel.winner === duel.tokenId1
                ? `${getShortAddress(duel.owner1)}${
                    duel.owner1 === account ? " (You)" : ""
                  }`
                : `${getShortAddress(duel.owner2)}${
                    duel.owner2 === account ? " (You)" : ""
                  }`}
            </p>
          )}
        </>
      </div>
    ) : null;
  };

  const handleClickDuel = (duel: IDuel) => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setSelectedDuel(duel);
  };

  const handleAcceptDuel = async (duel: IDuel) => {
    let duelIndex = null;
    for (let i = 0; i < all!.length; i++) {
      if (all![i].tokenId1 === duel.tokenId1) duelIndex = i;
    }
    if (duelIndex === null) return console.error("Could not find duel!");
    const response = await DegenArena.acceptDuel(duelIndex, duel);
    console.log(response);
    if (response.transactionHash) {
      window.location.reload();
    }
  };

  const onFilterClick = (_filter: number) => {
    setFilter(_filter);
    switch (_filter) {
      case 1:
        setFilteredDuals(
          duels.filter(
            (d) => d.tokenId1 !== "0" && d.tokenId2 === "0" && d.winner === "0"
          )
        );
        break;
      case 2:
        setFilteredDuals(
          duels.filter((d) => d.tokenId2 !== "0" && d.winner === "0")
        );
        break;
      case 3:
        setFilteredDuals(duels.filter((d) => d.winner !== "0"));
        break;
      default:
        setFilteredDuals(duels);
    }
  };

  const FilterButtons = () => {
    return (
      <>
        <a
          onClick={() => onFilterClick(0)}
          className={
            filter === 0
              ? classes.titleFilterButtonSelected
              : classes.titleFilterButton
          }
        >
          Show all
        </a>
        <a
          onClick={() => onFilterClick(1)}
          className={
            filter === 1
              ? classes.titleFilterButtonSelected
              : classes.titleFilterButton
          }
        >
          Available
        </a>
        <a
          onClick={() => onFilterClick(2)}
          className={
            filter === 2
              ? classes.titleFilterButtonSelected
              : classes.titleFilterButton
          }
        >
          Accepted
        </a>
        <a
          onClick={() => onFilterClick(3)}
          className={
            filter === 3
              ? classes.titleFilterButtonSelected
              : classes.titleFilterButton
          }
        >
          Previous
        </a>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>{title}</div>
        <div className={classes.titleFilters}>
          <FilterButtons />
        </div>
      </div>
      {filteredDuals.length > 0 ? (
        <>
          <AcceptDuel
            duel={selectedDuel}
            ownerTokens={ownerTokens}
            handleClick={(duel) => handleAcceptDuel(duel)}
            onClose={() => setSelectedDuel(undefined)}
          />
          <Grid container>
            <Grid item></Grid>
            <Grid item>
              <Grid container justifyContent={undefined} spacing={2}>
                {filteredDuals.map((item, index) => (
                  <Duel key={index} {...item} />
                ))}
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <p className={classes.noDuelsText}>Nothing to show yet</p>
      )}
    </div>
  );
}
