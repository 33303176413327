const mandrills = {
  id: "mandrills",
  title: "Voodoo Mandrills",
  description1: `Deep in the Serengeti, inside the darkest of caves you can hear their echoes. Whispering, plotting, and scheming, the Voodoo Mandrills have left the earthly world behind them in benefit for a higher power. Sitting in their caves, eating poisonous mushrooms and brewing magic potions the Mandrills’ have come across and managed to learn powers unknown to mankind.`,
  description2: `Deep in the Serengeti, inside the darkest of caves you can hear their echoes. Whispering, plotting, and scheming, the Voodoo Mandrills have left the earthly world behind them in benefit for a higher power. Sitting in their caves, eating poisonous mushrooms and brewing magic potions the Mandrills’ have come across and managed to learn powers unknown to mankind. 

  Dark magicians, shamans and evil witchcraft, the Mandrills and their powers have been called a lot of things. There are even rumors circulating that the Mandrills had to sell their soul to the Devil himself in order obtain their powers. No one except the Mandrills themselves know how and where their powers come from but it is clear that their voodoo powers work. 
  
  Do not let the Mandrills modest appearance fool you. They are ruthless and serve only themselves, grabbing what they want whenever they want it. What they might lack in muscles they make up for in power and carefully designed potions and items. For centuries these Mandrills have used their powers to successfully control the Serengeti landscape. However, their way of living is not cheap, and it requires a lot of resources. More specifically expensive resources. 
  
  The Mandrills have now decided to take their powers into the gladiator games in order to win enough money to secure their lifestyle necessary resources. Challenge them at your own risk, and if you find yourself doing something unexpected or self-harming the voodoo has probably gotten a hold of you and your faith is already sealed.`,
  image1: "mandrills/mandrills1-background.png",
  image2: "mandrills/mandrills2.png",
  backgroundImage: "mandrills/background-mandrills.png",
  unrevealed: true,
  properties: {
    attack: 50,
    defense: 60,
    speed: 30,
  },
  items: [
    {
      title: "Bottle of Acid",
      attribute: "Bottle_of_acid",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 30,
      rarity: 5,
      image: "mandrills/weapons/mandrills-acid.png",
      type: "attack",
    },
    {
      title: "Blade of Rituals",
      attribute: "Blade_of_rituals",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 25,
      rarity: 2,
      image: "mandrills/weapons/mandrills-blade-of-rituals.png",
      type: "attack",
    },
    {
      title: "Earth Wand",
      attribute: "Earth_wand",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 10,
      image: "mandrills/weapons/mandrills-earth-wand.png",
      type: "attack",
    },
    {
      title: "Fire Wand",
      attribute: "Fire_wand",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 70,
      rarity: 10,
      image: "mandrills/weapons/mandrills-fire-wand.png",
      type: "attack",
    },
    {
      title: "Magic Wand",
      attribute: "Magic_wand",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 10,
      image: "mandrills/weapons/mandrills-magic-wand.png",
      type: "attack",
    },
    {
      title: "Storm Wand",
      attribute: "Storm_wand",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 100,
      rarity: 5,
      image: "mandrills/weapons/mandrills-storm-wand.png",
      type: "attack",
    },
    {
      title: "Berserk Mushrooms",
      attribute: "Berserk_mushrooms",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 50,
      rarity: 10,
      image: "mandrills/weapons/mandrills-mushrooms.png",
      type: "attack",
    },
    {
      title: "Tribe Spear",
      attribute: "Tribe_spear",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 20,
      rarity: 10,
      image: "mandrills/weapons/mandrills-tribe-spear.png",
      type: "attack",
    },
    {
      title: "Water Wand",
      attribute: "Water_wand",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 8,
      image: "mandrills/weapons/mandrills-water-wand.png",
      type: "attack",
    },
    {
      title: "Wise mans Wand",
      attribute: "Wise_mans_wand",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 60,
      rarity: 10,
      image: "mandrills/weapons/mandrills-wise-mans-wand.png",
      type: "attack",
    },
    {
      title: "Whip",
      attribute: "Whip",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 35,
      rarity: 5,
      image: "mandrills/weapons/mandrills-whip.png",
      type: "attack",
    },
    {
      title: "Elephant Ivory",
      attribute: "Elephant_ivory",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 40,
      rarity: 5,
      image: "mandrills/weapons/mandrills-elephant-ivory.png",
      type: "attack",
    },
    {
      title: "Flint Axe",
      attribute: "Flint_axe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 40,
      rarity: 5,
      image: "mandrills/weapons/mandrills-flint-club.png",
      type: "attack",
    },
    {
      title: "Gold Axe",
      attribute: "Flint_axe_gold",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 40,
      rarity: 5,
      image: "mandrills/weapons/mandrills-flint-axe-gold.png",
      type: "attack",
    },

    {
      title: "Coat of Sacrifice",
      attribute: "Coat_of_sacrifice",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-coat-of-sacrifice.png",
      type: "item",
    },
    {
      title: "Earrings",
      attribute: "Earrings",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-earrings.png",
      type: "item",
    },
    {
      title: "Feather Headpiece",
      attribute: "Feather_Headpiece",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 20,
      image: "mandrills/items/mandrills-feather-headpiece.png",
      type: "defense",
    },
    {
      title: "Loger Cloth",
      attribute: "Loger_Cloth",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-loger-cloth.png",
      type: "item",
    },
    {
      title: "Loin Cloth",
      attribute: "Loin_Cloth",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-loincloth.png",
      type: "item",
    },
    {
      title: "Nose Bone",
      attribute: "Nose_Bone",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-nose-bone.png",
      type: "item",
    },
    {
      title: "Magic Necklace",
      attribute: "Magic_Necklace",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-necklace.png",
      type: "defense",
    },
    {
      title: "Nose Bone",
      attribute: "Nose_Bone",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-nose-bone.png",
      type: "item",
    },
    {
      title: "Nose Ring",
      attribute: "Nose_Ring",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-nose-ring.png",
      type: "item",
    },
    {
      title: "Herb Pipe",
      attribute: "Herb_Pipe",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-pipe.png",
      type: "item",
    },
    {
      title: "Green Gem Ring",
      attribute: "Green_Gem_Ring",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 30,
      rarity: 2,
      image: "mandrills/items/mandrills-ring-green.png",
      type: "defense",
    },
    {
      title: "Red Gem Ring",
      attribute: "Red_Gem_Ring",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 20,
      rarity: 5,
      image: "mandrills/items/mandrills-ring-red.png",
      type: "defense",
    },
    {
      title: "Shaman Mask",
      attribute: "Shaman_Mask",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-shaman-mask.png",
      type: "item",
    },
    {
      title: "Magic Potion",
      attribute: "Magic_potion",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-magic-potion.png",
      type: "item",
    },
    {
      title: "Top Cloth",
      attribute: "Top_Cloth",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-top-cloth.png",
      type: "item",
    },
    {
      title: "Torch",
      attribute: "Torch",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-torch.png",
      type: "item",
    },
    {
      title: "Tribe Shield",
      attribute: "Tribe_Shield",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-tribe-shield.png",
      type: "item",
    },
    {
      title: "Turban",
      attribute: "Turban",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-turban.png",
      type: "item",
    },
    {
      title: "Vest",
      attribute: "Vest",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.",
      value: 0,
      rarity: 0,
      image: "mandrills/items/mandrills-vest.png",
      type: "item",
    },
  ],
};

export default mandrills;
