import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import TwitterIcon from '@mui/icons-material/Twitter'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import InstagramIcon from '@mui/icons-material/Instagram'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { richard, elvin, fredrik } from '../../content/team'

type TeamMemberProps = {
    member: { name: string, role: string, bio: string, image: string, twitter: string, linkedIn: string, instagram: string }
}

export default function Team(){

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            background: 'linear-gradient(45deg, #171717 0%, #000000 70%)',
            textAlign: 'center'
        },
        teamMember: {
           padding: 30,
           marginBottom: 100
        },
        image: {
            width: 150,
            borderRadius: 10,
            marginTop: 10,
            opacity: 0.7
        },
        role: {
            color: 'gray',
            marginTop: -25
        },
        icon: {
            cursor: 'pointer'
        }
    }))

    const classes = useStyles()
    const Spacing = ({ value = 100 }: { value?: number }) => <div style={{ height: value }}></div>

    const TeamMember = ({ member } : TeamMemberProps) => {
        return (
            <Grid className={classes.teamMember} xs={12} sm={12} md={4} lg={4} xl={4} item={true}>
                <h3>{member.name}</h3>
                <div className={classes.role}>{member.role}</div>
                <img className={classes.image} alt={member.name} src={member.image}/>
                <p>{member.bio}</p>
                <Social member={member}/>
            </Grid>
        )
    }

    const Social = ({ member } : TeamMemberProps) => {
        return (
            <div>
                {member.twitter !== '' ? <TwitterIcon className={classes.icon} onClick={()=> window.open(member.twitter, '_blank')}/> : null}
                &nbsp;
                &nbsp;
                {member.linkedIn !== '' ?<LinkedInIcon className={classes.icon} onClick={()=> window.open(member.linkedIn, '_blank')}/> : null}
                &nbsp;
                &nbsp;
                {member.instagram !== '' ?<InstagramIcon className={classes.icon} onClick={()=> window.open(member.instagram, '_blank')}/> : null }
            </div>
        )
    }

    return (
        <div className={classes.root}>
            <Container>
                <Spacing/>
                <h1>Founding team</h1>
                <Grid container justifyContent="center">
                   <TeamMember member={richard}/>
                   <TeamMember member={elvin}/>
                   <TeamMember member={fredrik}/>
                </Grid>
             </Container>
        </div>
    )
}