import { useState } from "react";
import PropTypes from "prop-types";
import { SmallIcondButton, SmallTwitterButton } from "../ui/buttons";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { font, colors, menu, links } from "../../config/constants";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    float: "right",
  },
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  menuButton: {
    right: 10,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    background: colors.background,
    color: colors.text,
  },
  closeMenuButton: {
    marginLeft: "auto",
    marginRight: 0,
    color: colors.text,
  },
}));

export default function NavbarMenu() {
  const classes = useStyles();
  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = useState(false);

  function handleDrawerToggle() {
    setMobileOpen(!mobileOpen);
  }

  const drawer = (
    <div>
      <List>
        {menu.map((m, index) => (
          <ListItem button key={index}>
            <ListItemText
              disableTypography
              primary={m.title}
              style={{ fontFamily: font.family, fontSize: font.size.normal }}
              onClick={() => {
                navigate(m.url);
                handleDrawerToggle();
              }}
            />
          </ListItem>
        ))}
        <ListItem>
          <SmallIcondButton
            image="discord"
            handlePress={() => window.open(links.discord, "_blank")}
          />
          &nbsp; &nbsp; &nbsp;
          <SmallIcondButton
            image="opensea"
            handlePress={() => window.open(links.opensea, "_blank")}
          />
          &nbsp; &nbsp; &nbsp;
          <SmallTwitterButton />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <IconButton
        color="inherit"
        aria-label="Open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className={classes.menuButton}
      >
        <MenuIcon />
      </IconButton>

      <div className={classes.drawer}>
        <Hidden xsUp implementation="css">
          <Drawer
            variant="temporary"
            anchor="right"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            <IconButton
              onClick={handleDrawerToggle}
              className={classes.closeMenuButton}
            >
              <CloseIcon />
            </IconButton>
            {drawer}
          </Drawer>
        </Hidden>
      </div>
    </div>
  );
}

NavbarMenu.propTypes = {
  // Injected by the documentation to work in an iframe.
  // You won't need it on your project.
  container: PropTypes.object,
};
