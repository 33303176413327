
import { MerkleTree } from "merkletreejs"
import keccak256 from "keccak256"
import whitelist from '../config/lists/whitelist'

const getProof = (address: string) => {
    const leaves = whitelist.map(addr => keccak256(addr))
    const merkleTree = new MerkleTree(leaves, keccak256, { sortPairs: true })
    const hashedAddress = keccak256(address)
    return merkleTree.getHexProof(hashedAddress)
}

export {
    getProof
}