import Container from '@material-ui/core/Container'
import TribeSection from './components/TribeSection'
import { makeStyles, createStyles } from '@material-ui/core/styles'
import { tribes } from '../../config/tribes'

export default function Tribes() {

    const useStyles = makeStyles(() =>
        createStyles({
            root: {
                background: 'linear-gradient(45deg, #171717 0%, #000000 70%)'            }
        }))

    const classes = useStyles()
    const Spacing = ({ value = 100 }: { value?: number }) => <div style={{ height: value }}></div>

    return (
        <div className={classes.root}>
            <Container>
                <Spacing />
                <TribeSection
                    id={tribes.pandas.id}
                    isTextFirst={false} />

                <TribeSection
                    id={tribes.hyenas.id}
                    isTextFirst={true} />

                <TribeSection
                    id={tribes.hogs.id}
                    isTextFirst={false} />

                <TribeSection
                    id={tribes.mandrills.id}
                    isTextFirst={true} />
            </Container>
        </div>
    )
}

