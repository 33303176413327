import { useState } from "react";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Gladiator from "../../../services/Gladiator";
import { numberOfWhitelistMints } from "../../../config/lists/whitelist";

type MintFormProps = {
  isMintOpen: boolean;
  isMintDone: boolean;
  noOfFreeMints?: number;
  isWhitelist?: boolean;
};

export default function ConnectedWallet({
  isMintOpen,
  isMintDone,
  noOfFreeMints,
  isWhitelist,
}: MintFormProps) {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        marginTop: 100,
      },
      mintForm: {
        marginTop: 40,
      },
    })
  );

  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const [noOfMints, setNoOfMints] = useState(
    (noOfFreeMints ?? 0) > 1 ? noOfFreeMints ?? 0 : numberOfWhitelistMints
  );

  const handleClick = async () => {
    if (noOfMints < 1) return;
    setLoading(true);
    const isFreeMint = (noOfFreeMints ?? 0) >= noOfMints;
    const response = await Gladiator.mint(noOfMints, isFreeMint);
    console.log(response);
    setLoading(false);
    if (response.transactionHash) {
      console.log("SUCCESS!");
      window.location.reload();
    }
    // else if (
    //   response.message?.includes("User denied transaction signature")
    // ) {
    // }
  };

  const NoOfMintsInput = () => {
    const NumberOfMintsTextField = styled(TextField)({
      width: 150,
      marginRight: 10,
      "& input:valid + fieldset": {
        borderColor: "#9c27b0",
        borderWidth: 0.5,
      },
      "& .MuiFormLabel-root": {
        color: "#9c27b0",
      },
      "& .MuiInputBase-input": {
        height: 20,
        color: "white",
      },
    });

    return (
      <NumberOfMintsTextField
        id="outlined-basic"
        size="small"
        color="secondary"
        label="Number of mints"
        value={noOfMints}
        variant="outlined"
        autoFocus
        onChange={(e) => {
          if (e.target.value === "") return setNoOfMints(0);
          const valid = e.target.value.match(/^[0-9]*\.?[0-9]*$/) != null;
          if (!valid) return;
          setNoOfMints(parseInt(e.target.value));
        }}
      />
    );
  };

  const MintButton = () => {
    return (
      <Button
        color="secondary"
        variant="outlined"
        onClick={() => handleClick()}
      >
        Mint
      </Button>
    );
  };

  const Loading = () => <CircularProgress color="secondary" />;

  const MintForm = () => {
    const isAllowedToMint = (noOfFreeMints ?? 0) > 0 || (isWhitelist ?? false);
    if (!isAllowedToMint) return null;
    return !isLoading ? (
      <div className={classes.mintForm}>
        <NoOfMintsInput />
        <MintButton />
      </div>
    ) : (
      <Loading />
    );
  };

  return isMintOpen && !isMintDone ? <MintForm /> : null;
}
