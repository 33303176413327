import { makeStyles, createStyles } from '@material-ui/core/styles'
import { colors, paths } from '../../config/constants'

type FaqProps = {
    title: string
    text: string
    image?: string
}

export default function Faq({ title, text, image } : FaqProps){

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
            textAlign: 'left',
            marginRight: 40,
        },
        img: {
            background: image ? 'transparent' : colors.placeholder,
            height: 100,
            float: 'left',
            marginRight: 20,
        }
    }))

    const classes = useStyles()
    
    return (
        <div className={classes.root}>
            <h3>{title}</h3>
            <img className={classes.img} alt="" src={image ?? paths.placeholder}></img>
            <p>{text}</p>
        </div>
    )
}