import Button from "@material-ui/core/Button";
import TwitterIcon from "@mui/icons-material/Twitter";
import { makeStyles } from "@material-ui/core/styles";
import { colors, font, links } from "../../config/constants";
import { useNavigate } from "react-router-dom";

export const SmallTwitterButton = () => {
  const useStyles = makeStyles({
    root: {
      height: 55,
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  return (
    <TwitterIcon
      className={classes.root}
      onClick={() => window.open(links.twitter, "_blank")}
    />
  );
};

export const SmallIcondButton = ({
  image,
  handlePress,
}: {
  image: string;
  handlePress: () => void;
}) => {
  const useStyles = makeStyles({
    root: {
      width: 25,
      cursor: "pointer",
    },
  });
  const classes = useStyles();
  return (
    <img
      className={classes.root}
      alt=""
      src={`/img/icons/${image}.png`}
      onClick={handlePress}
    />
  );
};

export const MintButton = () => {
  const useStyles = makeStyles({
    root: {
      width: "100%",
      marginTop: 40,
    },
    button: {
      border: `1.5px solid ${colors.text}`,
      color: colors.text,
      fontFamily: font.family,
      fontSize: font.size.normal,
      boxShadow: "bold",
      borderRadius: 8,
      textTransform: "none",
      paddingLeft: 15,
      paddingRight: 15,
      paddingTop: 7,
    },
    image: {
      width: 35,
    },
  });
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.root}>
      <Button
        className={classes.button}
        size="small"
        onClick={() => navigate("/mint")}
      >
        Mint
      </Button>
    </div>
  );
};
