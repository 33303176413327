
import Button from '@mui/material/Button'
import { connectWallet } from '../../../services/Web3'

export default function ConnectWallet() {

    return (
        <div>
            <Button
                color="success"
                variant="outlined"
                onClick={() => {
                    connectWallet()
                }}>Connect wallet</Button>
        </div>
    )

}