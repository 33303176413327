import Grid from '@material-ui/core/Grid'
import Box from '@mui/material/Box'
import LinearProgress from "@material-ui/core/LinearProgress"
import { TribePropertyIcon } from '../../../components/ui/icons' 
import { makeStyles, createStyles, withStyles } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { getTribeById } from '../../../config/tribes'

type GladiatorSectionProps = {
    id: string
}

type GladiatorPropertyProps = {
    title: string
    value: number
    icon: string
}

export default function TribeExtensiveInfo({ id } : GladiatorSectionProps){

    const tribe = getTribeById(id)
    const xs = useMediaQuery('(max-width:600px) and (min-width:0px)')
    const sm = useMediaQuery('(max-width:960px) and (min-width:600px)')

    const useStyles = makeStyles(() =>
    createStyles({
        root: {
        },
        imageGrid: {
            height: sm ? '40vh' : '60vh',
            marginRight: xs ? 0 : 5,
            marginLeft: xs ? 0 : 5,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        img: {
            height: 450,
        },
        gladiatorProperties: {
            marginTop: 20,
            marginLeft: 5,
            marginBottom: 40
        },
        title: {
            marginLeft: 25
        },
        textGrid: {
            marginRight: xs ? 0 : 30,
            marginLeft: xs ? 0 : 30,
            marginBottom: 40,
            whiteSpace: 'pre-line',
            overflow: 'scroll',
            width: '100%',
        }
    }))

    const classes = useStyles()

    const StyledLinearProgress = withStyles({
        colorPrimary: {
            height: 10,
            borderRadius: 5,
            backgroundColor: "#292929"
        },
        barColorPrimary: {
          backgroundColor: "white",
        }
      })(LinearProgress);
    
    const GladiatorProperty = ({ title, value, icon } : GladiatorPropertyProps) => { 
        const useStyles = makeStyles(() =>
        createStyles({
            root: {
                marginTop: 15
            },
            title: {
                fontWeight: 'bold',
                marginBottom: 2
            }
        }))
        const classes = useStyles()
        return (
            <div className={classes.root}>
                <div className={classes.title}><TribePropertyIcon icon={icon}/>&nbsp;{title}</div>
                <StyledLinearProgress variant="determinate" value={value} />
            </div>
        )
    }

    const ImageGrid = () => {
        
        return (
            <Grid xs={12} sm={4} md={4} lg={4} xl={4} item={true}>
                <div className={classes.imageGrid}>
                    <img className={classes.img} alt="mocked" src={`/img/${tribe.image2}`}/>
                </div>
                <Box className={classes.gladiatorProperties} sx={{ width: '95%' }}>
                   <GladiatorProperty title="Attack" icon="attack" value={tribe.properties.attack}/>
                   <GladiatorProperty title="Defense" icon="defense" value={tribe.properties.defense}/>
                   <GladiatorProperty title="Speed" icon="speed" value={tribe.properties.speed}/>
                </Box>
            </Grid>
        )
    }

    const TextGrid = () => {
        return (
            <Grid xs={12} sm={8} md={8} lg={8} xl={8} item={true}>
                <h1 className={classes.title}>{tribe.title}</h1>
                <div className={classes.textGrid}>
                    <div>{tribe.description2}</div>
                </div>
            </Grid>
        )
    }

    return (
        <Grid container justifyContent="center" item={true}>
            <ImageGrid/>
            <TextGrid/>
        </Grid>
    )
}

