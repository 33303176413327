import { initializeApp } from "firebase/app";
import { 
  getAnalytics,
  logEvent
} from "firebase/analytics";

const isDev = false

const firebaseConfigDev = {
  apiKey: "AIzaSyC9cQxV_ENp4LZDaskQEVfwVFPl_0jtQGo",
  authDomain: "mg-dev-e6b45.firebaseapp.com",
  projectId: "mg-dev-e6b45",
  storageBucket: "mg-dev-e6b45.appspot.com",
  messagingSenderId: "374558473483",
  appId: "1:374558473483:web:433195cd8fc3474f61760e",
  measurementId: "G-PYSCBLZZR5"
}

const firebaseConfigProd = {
  apiKey: "AIzaSyDKF8I89LqnzPaYcel7nPqYeztsBdkPkUI",
  authDomain: "mg-prod.firebaseapp.com",
  projectId: "mg-prod",
  storageBucket: "mg-prod.appspot.com",
  messagingSenderId: "845258815128",
  appId: "1:845258815128:web:2c64060116200fc19d82ed",
  measurementId: "G-KT8NK17HTM"
}

const app = initializeApp(isDev ? firebaseConfigDev : firebaseConfigProd)
const analytics = getAnalytics(app)
const log = {
  event: (text: string, data: any = {}) => logEvent(analytics, text, data),
  clicked: (text: string, data: any = {}) => logEvent(analytics, `clicked_${text}`, data),
  error: (text: string, data: any = {}) => logEvent(analytics, `error_${text}`, data)
}

export {
  log
}