import LinearProgress from "@material-ui/core/LinearProgress";
import { TribePropertyIcon } from "../ui/icons";
import { makeStyles, createStyles, withStyles } from "@material-ui/core/styles";
import { colors, font } from "../../config/constants";

type TribePropertyProps = {
  title: string;
  value: number;
  icon: string;
};

export default function TribeProperty({
  title,
  value,
  icon,
}: TribePropertyProps) {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        textAlign: "left",
        marginRight: 40,
        marginBottom: 15,
      },
      img: {
        background: colors.placeholder,
        height: 90,
        float: "left",
        marginRight: 10,
        marginBottom: 5,
      },
      title: {
        fontSize: font.size.normal,
        fontWeight: "bold",
      },
    })
  );

  const StyledLinearProgress = withStyles({
    colorPrimary: {
      backgroundColor: "#292929",
      minHeight: 8,
    },
    barColorPrimary: {
      backgroundColor: "white",
    },
  })(LinearProgress);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        {<TribePropertyIcon icon={icon} />}&nbsp;{title}
      </div>
      <StyledLinearProgress variant="determinate" value={value} />
    </div>
  );
}
