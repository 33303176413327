import { getWeb3, getAccount } from "./Web3";
import { degenArenaContractAddress } from "../config/constants";
import web3 from "web3";
const json = require("../contracts/DegenArena.json");

export interface IDuel {
  tokenId1: string;
  tokenId2: string;
  owner1: string;
  owner2: string;
  winner: string;
  stake: string;
}

export interface IDuels {
  title: string;
  duels: Array<IDuel>;
  all: Array<IDuel>;
  ownerTokens: Array<string>;
  account: string;
}

export interface IDuelSet {
  all: Array<IDuel>;
  account: Array<IDuel>;
}

export default class DegenArena {
  static async getContract() {
    const web3 = await getWeb3();
    return new web3.eth.Contract(json.abi, degenArenaContractAddress);
  }

  static async arenaClosesAt() {
    const degenArena = await DegenArena.getContract();
    const response = await degenArena.methods.arenaClosesAt().call();
    return parseInt(response) ?? 0;
  }

  static async createDuel(tokenId: string, stakedEth: string) {
    const degenArena = await DegenArena.getContract();
    const account = await getAccount();
    const arenaFee = await degenArena.methods.arenaFee().call();
    const arenaFeeBN = web3.utils.toBN(arenaFee);
    const stakedBN = web3.utils.toBN(web3.utils.toWei(stakedEth, "ether"));
    try {
      return await degenArena.methods.createDuel(tokenId).send({
        from: account,
        value: stakedBN.add(arenaFeeBN),
      });
    } catch (error: any) {
      return error;
    }
  }

  static async acceptDuel(duelIndex: number, duel: IDuel) {
    const degenArena = await DegenArena.getContract();
    const account = await getAccount();
    const arenaFee = await degenArena.methods.arenaFee().call();
    const arenaFeeBN = web3.utils.toBN(arenaFee);
    const stakedBN = web3.utils.toBN(duel.stake);
    try {
      return await degenArena.methods
        .acceptDuel(duel.tokenId2, duelIndex)
        .send({
          from: account,
          value: stakedBN.add(arenaFeeBN),
        });
    } catch (error: any) {
      return error;
    }
  }

  static async getDuels() {
    const account = await getAccount();
    const duels: IDuelSet = {
      all: Array<any>(),
      account: Array<any>(),
    };
    const degenArena = await DegenArena.getContract();
    const _duels = await degenArena.methods.getDuels().call();
    duels.all = _duels;
    for (let i = 0; i < _duels.length; i++) {
      if (_duels[i].tokenId1 === "0") {
        // Withdrawn
        continue;
      } else if (_duels[i].owner1 === account || _duels[i].owner2 === account) {
        duels.account.push(_duels[i]);
      }
    }
    return duels;
  }

  static async withdraw(tokenId: string) {
    const degenArena = await DegenArena.getContract();
    const account = await getAccount();
    try {
      return await degenArena.methods.withdrawGladiatorFromDuel(tokenId).send({
        from: account,
      });
    } catch (error: any) {
      return error;
    }
  }
}
