const isMainnet = true;
const environment = isMainnet ? "mainnet" : "rinkeby";

const mainnet = {
  gladiatorAddress: "0xe368CD117112bd229145cceC5bF391fC1f1Aa299",
  degenArenaAddress: "0x661d8AEb25e50A36d637c8A48DC84c5e8d782C45",
  networkId: 1,
  chainId: "0x1",
};

const rinkeby = {
  gladiatorAddress: "0xE2c907D08c80e57594A4f1c84Cf890A689d2bDC3",
  degenArenaAddress: "0xb66a6e605A56cACAe78F297D41262d9B9818315D",
  networkId: 4,
  chainId: "0x4",
};

console.log("environment: ", environment);

const networkId =
  environment === "mainnet" ? mainnet.networkId : rinkeby.networkId;
const chainId = environment === "mainnet" ? mainnet.chainId : rinkeby.chainId;
const gladiatorContractAddress =
  environment === "mainnet"
    ? mainnet.gladiatorAddress
    : rinkeby.gladiatorAddress;
const degenArenaContractAddress =
  environment === "mainnet"
    ? mainnet.degenArenaAddress
    : rinkeby.degenArenaAddress;

export {
  environment,
  networkId,
  chainId,
  gladiatorContractAddress,
  degenArenaContractAddress,
};
