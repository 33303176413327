const roadmap = {
  title: "Roadmap",
  text:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  milestone1: {
    title: "Milestone #1 - Q2 2022",
    text: "Release of the remaining tribe - Voodoo Mandrills",
  },
  milestone2: {
    title: "Milestone #2 - Q2 2022",
    text: "Audit of the relevant contracts before mint.",
  },
  milestone3: {
    title: "Milestone #3 - Q2 2022",
    text:
      "Mint! A free mint genesis batch of 400 gladiators will be available to mint.",
  },
  milestone4: {
    title: "Milestone #4 - Q2 2022",
    text:
      "Reveal of the NFTs, the individual items of each gladiator, their respective rarity, including their relevant tribe points.",
  },
  milestone5: {
    title: "Milestone #5 - Q3 2022",
    text:
      "Opening of the Degen Arena, allowing participants to register and compete in the Degen Arena for the first time.",
  },
  milestone6: {
    title: "Milestone #6 - TBA",
    text: "Drop remaining 4600 gladiators.",
  },
  // milestone7: {
  //   title: "Milestone #7 - Q3/Q4 2022",
  //   text:
  //     "TBD - Opening of the Deathmatch Arena, allowing participants to register and compete in the gladiator games for the first time.",
  // },
  // milestone8: {
  //   title: "Milestone #8 - Q3/Q4 2022",
  //   text:
  //     "TBD - Opening of the Tribe Wars arena, allowing participants to register and compete in the Tribe Wars arena for the first time.",
  // },
};

export { roadmap };
