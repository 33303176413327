import Container from "@material-ui/core/Container";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import Arena from "../../components/arena/Arena";
import { arena1, arena2, arena3 } from "../../config/arenas";

export default function Arenas() {
  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        textAlign: "center",
        background: "linear-gradient(45deg, #171717 0%, #000000 70%)",
      },
    })
  );

  const classes = useStyles();

  const Spacing = ({ value = 100 }: { value?: number }) => (
    <div style={{ height: value }}></div>
  );

  return (
    <div className={classes.root}>
      <Container>
        <Spacing />
        <Arena
          id={arena3.id}
          title={arena3.title}
          description={arena3.description}
          image={arena3.logo}
        />
        <Arena
          id={arena1.id}
          title={arena1.title}
          description={arena1.description}
          image={arena1.logo}
        />
        <Spacing />
        <Arena
          id={arena2.id}
          title={arena2.title}
          description={arena2.description}
          image={arena2.logo}
        />
        <Spacing />

        <Spacing />
      </Container>
    </div>
  );
}
