const whitelist = [
  "0x9C19964a79677859C8E30d632a665876b958bdB1",
  "0xB6bF2F97C42506602dbb2a2FC2e588486d6edd68",
  "0x4E0ECe5C34a616d591475A075FC4666720e82Cc5",
  "0xecE4047611d3F4b55978248ee455699b62761bbf",
  "0xdaE847EECb924C0A74d77Ce5ad652FC7edD47061",
  "0xF860C21abAa19e1afd03aECF4C660B0fE09bF511",
  "0x5b7ea7970DF4ae06528a54ae7a1c3aeC25525Ee5",
  "0x090DEb24A178AD520f4C7575906f55d6Ed4597Bc",
  "0xd173B75F5236e19aF69A57EA2775B2bE26CC75D3",
  "0x7D7d9b8A9F301F25FbF52295fA9B00547aBEc656",
  "0xAa94EFc0e7806298cd7C8AC130568C96C4dCD0cF",
  "0x0b3bfe556e31f3c44e2004d79DE4AF01f3f362Fe",
  "0xCe174c598F5ceDcE7f553b922A7B1c07159A6a76",
  "0xfd1eC95D0bAeB954Dc62776D2b824692922577e7",
  "0x6E13a1EbF67D41a6f8c951D748C6a27771F6804B",
  "0xd543448e706E931eC7B80b43073F2F360014e5d8",
  "0x1cdC556E53d3995daAbfaddFf463277804EF4774",
  "0x7C85D1682A48Ae42ed1923B53e7B0e2C4bd72b05",
  "0xA7045794eBBF6b0d25Ccd741c8A838d8c9687782",
  "0x0C406B93d9CFf242931103e569c17baF4066c3cA",
  "0x8EcCC9D9d51E18Ed14aDC1a3ACB0C3A46c7d6eDe",
  "0x8Ab52Ca9C4F2Ef61aB2F6e8Aed49A74b13404A33",
  "0x05356A57A81700aFf9Df661a08755ECe3ca1678d",
  "0x887705b33DeB7d6d3c7a813d7d2340E45217E8C4",
  "0xC18093ABB3cb6f018aF70D5Df8cDEfC077BCe5c7",
  "0x174De1CD3913671BF168A9885898E283DF273B7d",
  "0x9D3ff4d7C229635a3B5708664C728DAd30ADABd0",
  "0xccba16c817B84dC0AD955b4396CEDeBfd9Bc855C",
  "0x0D8F0e4b5567c38BDcA2BF249d1F1378d933bC0f",
  "0xa2Ce11Ed08d9ab11AaE9F80C0184870581D7086e",
  "0xc26b55eD3c5d0f465975417AD7F6154a164a809c",
  "0xE83deD34AdbE4B52B1611dCA0ea4FCF23F3eE01f",
  "0x8e4bd57f0B7959c01BA1F138c7BFC5f6F3efC6Da",
  "0xCAb5e813fa34e1C4094888EeA3D37418ee550427",
  "0x4bDbed0344c102aCbBa784403774e09913BC7797",
  "0xC56422470d8D8E809fb9C59Db79dBD61103D72f0",
  "0x9a03195f9e34fE6eC0769d9c63be633c2778076a",
  "0xBf251cFEb5F17D4fe9790C68DF578423E4073F66",
  "0x1e50eae55a68f91d1fa415e29B9DB2e07e23Fe3E",
  "0x545c43Cf7Aff544940E460c61B72437a318F2B6E",
  "0x74AdeE5Ac4F730bA84e022AfC35F83076b756241",
  "0x7769c02EC0ee580E381B8746ac2093CB8c45d23A",
  "0x76b86aA80d0B114DE6692fB6E71e6459B3a3aED6",
  "0x2D6d93ED44C0B2fDB827F24aAacCB1CA06ddF203",
  "0x296a012981C1C9f5665c3ddca84c42173fe75a6E",
  "0x9eDa173B60B105d2E255A1f535f70dB2587eEFe0",
  "0xF31bF547520EE45EB66757d96953ADe54213E9c3",
  "0x4f08BFf4D7b8119663BA909FbF1762FbA785e7F9",
  "0xA4079e0840170c846e953631Bd1cb5A0dFE324d6",
  "0xf0BB60DA8340679B970C74144718804e908752DB",
  "0x69Aaf99f1bd9f23fEf792f31129E6520C8F1E83a",
  "0x220eE648EBE5bb4FE52CB24898d51E4449EFA42b",
  "0x4dCf6e88e82EBA9B626ae13146b03bfB3c81980D",
  "0x4031aD85104Def177E04fe1345107B5744D41e6A",
  "0xaCDCc990b4A006c417464859E42C9C341A076a5A",
  "0xdDd1918AC0D873eb02feD2ac24251da75d983Fed",
  "0xf386030c6Ef203cBc7d69E83aDaea01f6D897C67",
  "0x3255Aa15CE5d724D1c5654280835A3DbFB049A39",
  "0x70575A31709f40A0536173266D9913fbd54b8194",
  "0x7ADEE4C1Ec5427519A0cb78E354828E6dA58e871",
  "0x6f93aB1a84d8DbABB4dE2f7C40F107b84c7Ba9C3",
  "0x53E54aEd28A840719aFb4a7d0828807d0424B95a",
  "0xE98D60e7e9E43FC47001dc3181EAAFbCE529A60d",
  "0xc2edc94d9179f2f6059164103da858eace192a7c",
  "0xdC67D437a4C925699AE5891E00f765cEA813c91f",
  "0xaA7951b8a080542e9d16aE3f8a835cBd7708D785",
  "0xcF10A8E7c907144cc87721aC1fD7Ac75a8aebeC7",
  "0x8922639Fd2B3000864448Ff52c81d6d6F4B56FB5",
  "0x8Fe27026057bC879A6Cb06eB042F6ec70EB3Ed8a",
  "0x91df7D10b2F705507718F8bAd01Ba1eC3E11E3bb",
  "0x7080926A161dC783c5C6f68Cd3f01353f8e5598C",
  "0x4114fFbD24aC78924D85b7a5726B83690BCB9611",
  "0x0a3c1607397003D3426bB26c0576CE49f778FaC1",
  "0x61615945F28848f1735bFa596949da3dDdD8000D",
  "0x199f55ebb1ecCFf9D5D3346334AB199dB729551c",
  "0x89E3ADE0B617b57b4f8856C80DFD8485180FA274",
  "0x0Ce8C92f16ED1490d110F3e03f39634b78000fd9",
  "0x8E1d479823E8cE778a7f228Bf5ed1606544e5d52",
  "0x9802D5DF171c3F9c74CFaD7e6Bced8619BB65E8e",
  "0x56487dE67A5bE8fD5a6Ae906c04A22Df4b98a2d3",
  "0x44378d4c0b6720ccB43Bb76a03b2c586906136C5",
  "0xc439e08a6bA59c6F0Dbe59E0dB657ba39bCF4Df7",
  "0x42b58be835e2f4495c0fca69af64328539f31bda",
  "0x7893d1d327C4650198a9fFB1688B14FDd33B2314",
  "0x9d279a05261D0Dd07174ea6F0F553D86BB3e779b",
  "0x4CAF42ce0506CDB27733c214b33D75a956bdAf03",
  "0x1427F0ffDAC58a03911D498dFaf68e9Ffaf2616C",
  "0x55caC8c88C84723eB8Df4D9054EcF030Cc82A774",
  "0xAeA7B7805d51CD7978Ef12f79a238bE2427d44CC",
  "0xa579F766406d0e18d8c7ff81d6e2db1dc3063943",
  "0x28530657fa5fA8EfFb04c3F7Ca1604ab0f74c4F2",
  "0x4be54BA3571438F94Eab4F7dAaC932230349EE7a",
  "0x02011804e65731E3d1C168ED6AE52A1f41d9bD20",
  "0xBf6304F05eA75CB2d76B90E24a9Fb0E01832933F",
  "0x3250c4D030b840AC5b014C56B911085e2072D497",
  "0xa626cC1d96838E244c4445836F32126C78DE642b",
  "0xF0a23d6f277e11dDd21f46FDE34AC23b405885c8",
  "0x5C74b292e1974afB4cC125ae6A0c86A7CEFEcb0B",
  "0x5e36dc8d111e415D38F70Be80ea1d2A37e46d1f8",
  "0x04A80F9184B582388dBD739379338118FA645a88",
  "0x7F45C37Ec37CbC24fe4b8A2a414ff978d966931e",
  "0x1ebf4A755E979dA70924AD995d559AA1e363F21B",
  "0x8c509E461bCEbe90711E3eB99A6EF1F1dDdE3351",
  "0x2539BF87Dc7b3622F46bB8067Aa7fcd225745CaA",
  "0x6b6a2bcc8df154461f6942d6a589e38711af647a",
  "0x1a4fb7cb926ac248dcc4beac55c882973a77d0cc",
  "0xe093bdcf792bb911ee935ef01518012b8e18f404",
  "0x28aca53f9962bef187ee5df733124a5aee7c50a5",
  "0x40c33a3d5d5302611cf3c94f195586523348347f",
  "0xfeeee0843d0934f89e35c5e0479bc605c05d270c",
  "0x191e03b2c1cae6bb31900e6345025c05ae9987fb",
  "0xB198e46d79324d9881E1a098B7D405B4fEad46F2",
  "0xc05B257E8C3b0F1a53a184bEbFA9690c040A87D6",
  "0x14Ec900c30feDF32C3fBF1b97CB2E925Af8237E7",
  "0x36Ed3285d3f36875a1a435b8a55AB795A81c6Bd6",
  "0x62ed0F883323A62FC689E3dAa96294Fb81bf53c5",
  "0xA65aae78EdEF916d4102BA7b5672068C0D35fbff",
  "0x4eed22A355aF50a27ad1a262AC2F73963A9dbC56",
  "0xf40C777bC7Bd3F5B104416c6c0E759D17E1711b8",
  "0x30d8277f61C6C04c599Bce5b05FaCE06CdB63268",
  "0x15A2b2C65F0f273aABd1d26C92CCdf5cB24F66b5",
  "0x6469E081f3c509a720A2b4B9845bB51CCfEbcE12",
  "0xf55A59908dCbf635565412d878ba16864f3a1a46",
  "0xD2129fbc59A674d36023B8257A9516bf1449FA64",
  "0xB655924b267Fcf0E40d62f5401D1084778Fb7268",
  "0x4Ad660e2d764eC9eE80cC5074393D15db2De32B4",
  "0xD44E72d8BCBdF7996e21b0501629c74829735B92",
  "0x2315468296eAC1d85746bbA3EB2182aeDc6Bc55A",
  "0x40bc16B1303dbE105209df8F081AeCD39A381E5a",
  "0x21b78F0826f625c68380DaBF3a0C87ab6663e35F",
  "0x3363ec9A6A3653d94D098B7E76d22C0460570528",
  "0xb7C3c2B46914b13543836Bb93f8F0e26A041cdc5",
  "0x55fa0B90Fe6aFe557cC62393fB77c7025ADD1e70",
  "0x29CC992a0BeD7B0f15b4592c40Dc81275d660d64",
  "0x7366D9e285D06eA6e9cbaFcBA9ea207Dd979E66D",
  "0x7672f7EC15cD4e4D921Ab814c9fD03eAD0AcC612",
  "0x19a267a684E45F9606209B58CbAD77E745F3fe59",
  "0x26Ee9342fbCB709d1145f9afFb6f3d23F2256e7d",
  "0x3e318F492EF53Ce112079845F712A88A8F8C0586",
  "0xd9d2f1310945BADaAd09163b669c908f75DE299A",
  "0x11c4741fC0dbe1D4570E8b6f5b12d8BcD817d9da",
  "0xAB69eb3485C9f3E314faE75C162135b5768F65Ff",
  "0xB72BB2c518b9D1E83963BFa148f104b511179b2F",
  "0x843E65F6624B0709E45E3c42Cb79EAF75C2cdF21",
  "0xd164166614b5Cd35E699c2CE001EE3302611ab5e",
  "0x76D6c95DF6be289e18c5a682DcC4263eC6DbA53f",
  "0x4d9218ec1270aB3B7D79f738AA524c9b60943ED0",
  "0xaA148F1BFB4117057eE26F6c6db5dcB63985eEb5",
  "0x9eA0C4BB61096ade0638E52bf0241deA5c2424aD",
  "0x705D2488020B87cAC572C58999832B771c8f6e80",
  "0x4f9677bfaBaFaa80F8bbc8Ad8a9A6A84248BEa40",
  "0xEf1cd279320302f08deb5cd58A9E5118A74AB101",
  "0xF251efb1d2f28204B2cd77FF30e9dc7564ADFaFD",
  "0x1bd06653d474eF3d30E2057242a07A5E976Fb91f",
  "0x6F2de9Df2D9d678C988857ac157f8eef24Bd7729",
  "0xa25B3A89a11Cb5E040Dc06BB0E8BE82ad7cBfdfA",
  "0xa9E01758fa7AA528B8ea2BF927E371b48D023D50",
  "0x587D714453eCcD6F49818da4C17121c092506cCe",
  "0xA9e4f0b5f489555077830EdC89D0EbB623c8f563",
  "0x8592fEc3586916dC01619905E3078b8CD332A9cE",
  "0x3C84Cc0d5CA86B7C3c29303E02B91F29cD2A83Bb",
  "0x074eb52F3BA10163B19FD1D1713dba1924f0E638",
  "0xb478469323FD4bd5311cd712F2d657e5567350E6",
  "0x7F8C8bf01B2635c036E37b7CE4F4451C77154826",
  "0xf3817726e108cc1ac4dF894D1abF648478011440",
  "0xf3476b36fc9942083049C04e9404516703369ef3",
  "0xAa6859986ba0BaCca92B0B7AECf18ed2Ba64F896",
  "0xce88ddB350B622eC6B63D585a21f02B53fCe6F8C",
  "0x70147829cf79978BBb3Ed44F6141c1A520e14f7C",
  "0x867F7Cb2d30fd07FfAD5061FcCB302d0b45cFfFf",
  "0xBA78CD28F7132958235D278fF3C5DC5E6d34cc15",
  "0xa14d4242A42Aa1995A2c0fb2bf96fDf15f4759ab",
  "0xfd6D3DaC0e787A8Ab01f5ab5F4a1c877790F27AF",
  "0x1392d44498504b5520e21fc061ebF1aF3A508F69",

  "0xcD0DEE491644dB2D62Bc7852fe7DEA54E85A777c",
  "0x1392d44498504b5520e21fc061ebF1aF3A508F69",
  "0xf55A59908dCbf635565412d878ba16864f3a1a46",
  "0xff90239C55b2C715EE7578Cf3D099b613C122eeF",

  "0x0bCF236552Fa88c15cAFaCC927dacD684aaC34Cf",
  "0x78c17d37f0d78949Ff7B66804cF26468AF7d20Bb",
  "0x7054ddbBc1A354220A9F5BdA3afae150303E643d",
  "0x4e35424E549cB6E26b69c59f4F014B821a3a1203",
  "0x0aAAFb1F140907eb6Db310EeE1E36a97F0B0Ca27",
  "0x24691250120E01eD345D93288e7aB1667A1EF29f",
  "0xD094401958aA867a286Af673C6A6EfB4De5b3773",
];

export const numberOfWhitelistMints = 4;

export default whitelist;
