import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { roadmap } from "../../content/roadmap";

type MilestoneProps = {
  title: string;
  text: string;
  isEnd?: boolean;
};

export default function Roadmap() {
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");

  const useStyles = makeStyles(() =>
    createStyles({
      root: {
        marginBottom: 150,
        textAlign: "center",
        background: "linear-gradient(45deg, #171717 0%, #000000 70%)",
      },
      title: {
        marginBottom: 80,
      },
      text: {
        marginBottom: 100,
        marginRight: xs ? 0 : 40,
        marginLeft: xs ? 0 : 40,
      },
    })
  );

  const classes = useStyles();

  const Spacing = ({ value = 100 }: { value?: number }) => (
    <div style={{ height: value }}></div>
  );

  const Milestone = ({ title, text, isEnd = false }: MilestoneProps) => {
    const useStyles = makeStyles(() =>
      createStyles({
        title: {
          fontWeight: "bold",
          textAlign: "left",
        },
        text: {
          textAlign: "left",
          marginBottom: 60,
        },
        line: {
          height: "100%",
          borderLeft: "3px dotted #242424",
        },
        dot: {
          height: 10,
          width: 10,
          background: "black",
          marginLeft: -5,
          borderRadius: 15,
          border: "2px solid #242424",
        },
        time: {
          marginLeft: 40,
        },
      })
    );

    const classes = useStyles();

    return (
      <>
        <Grid xs={1} sm={1} md={1} lg={1} xl={1} item={true}>
          <div className={classes.dot} />
          {!isEnd && <div className={classes.line} />}
        </Grid>
        <Grid xs={11} sm={11} md={11} lg={11} xl={11} item={true}>
          <div className={classes.title}> {title} </div>
          <div className={classes.text}> {text} </div>
        </Grid>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <Container>
        <Spacing />
        <h2 className={classes.title}>Roadmap</h2>
        <Grid container justifyContent="center">
          <Milestone
            title={roadmap.milestone1.title}
            text={roadmap.milestone1.text}
          />
          <Milestone
            title={roadmap.milestone2.title}
            text={roadmap.milestone2.text}
          />
          <Milestone
            title={roadmap.milestone3.title}
            text={roadmap.milestone3.text}
          />
          <Milestone
            title={roadmap.milestone4.title}
            text={roadmap.milestone4.text}
          />
          <Milestone
            title={roadmap.milestone5.title}
            text={roadmap.milestone5.text}
          />
          <Milestone
            title={roadmap.milestone6.title}
            text={roadmap.milestone6.text}
          />
          {/* <Milestone
            title={roadmap.milestone7.title}
            text={roadmap.milestone7.text}
          />
          <Milestone
            title={roadmap.milestone8.title}
            text={roadmap.milestone8.text}
          /> */}
        </Grid>
      </Container>
      <Spacing />
    </div>
  );
}
