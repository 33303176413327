import { useEffect, useState } from "react";
import { ethers } from "ethers";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Gladiator from "../../../../services/Gladiator";
import Button from "@mui/material/Button";
import axios from "axios";
import { IDuel } from "../../../../services/DegenArena";
import { links } from "../../../../config/constants";

interface IAcceptDuel {
  handleClick: (duel: IDuel) => void;
  onClose: () => void;
  ownerTokens: Array<string>;
  duel?: IDuel;
}

export default function AcceptDuel({
  handleClick,
  onClose,
  ownerTokens,
  duel,
}: IAcceptDuel) {
  const [selectedGladiator, setSelectedGladiator] = useState<string>();
  const [propability, setPropability] = useState("");
  const [isApproved, setApproved] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [metadata1, setMetadata1] = useState({
    name: "Loading...",
    attributes: [],
  });
  const [metadata2, setMetadata2] = useState({
    name: "Loading...",
    attributes: [],
  });
  const xs = useMediaQuery("(max-width:600px) and (min-width:0px)");

  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        textAlign: "center",
        marginTop: 40,
      },
      modal: {
        zIndex: 2,
        position: "absolute",
        top: 0,
        left: 0,
        background: "rgba(0,0,0,0.9)",
        width: "100%",
        borderBottom: "0.5px solid gray",
      },
      acceptDuelButton: {
        marginTop: -10,
        marginBottom: 20,
      },
    })
  );
  const classes = useStyles();

  const Modal = () => {
    const title =
      propability !== "100"
        ? `Chance of winning: ${propability}%`
        : "Loading...";
    return (
      <div
        className={classes.modal}
        onClick={() => onClose()}
        style={{ display: duel ? "block" : "none" }}
      >
        {propability ? (
          <>
            <h2>{title}</h2>
            {selectedGladiator && (
              <div className={classes.acceptDuelButton}>
                {isApproved ? (
                  <>
                    {!isLoading ? (
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={(e) => {
                          const _duel: IDuel = {
                            tokenId1: duel!.tokenId1,
                            tokenId2: selectedGladiator,
                            stake: duel!.stake,
                            owner1: duel!.owner1,
                            owner2: duel!.owner2,
                            winner: duel!.winner,
                          };
                          e.stopPropagation();
                          setLoading(true);
                          handleClick(_duel);
                        }}
                      >
                        Accept duel
                      </Button>
                    ) : (
                      <Loading />
                    )}
                    {duel!.stake !== "0" && (
                      <p>
                        Stake: Ξ{" "}
                        <strong>{ethers.utils.formatEther(duel!.stake)}</strong>
                      </p>
                    )}
                  </>
                ) : (
                  <>
                    {!isLoading ? (
                      <Button
                        color="secondary"
                        variant="outlined"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleClickApprove();
                        }}
                      >
                        Approve degen arena and accept duel
                      </Button>
                    ) : (
                      <Loading />
                    )}
                  </>
                )}
              </div>
            )}
          </>
        ) : (
          <h2>Choose gladiator</h2>
        )}
        <GladiatorCards />
        {/* {ownerTokens.length > 0 ? (
          <GladiatorCards />
        ) : (
          <div style={{ paddingRight: 20, paddingLeft: 20 }}>
            <p>
              ⚠️ You need a Gladiator NFT to play and you are not holding any
              yet!
            </p>
            <Button
              color="secondary"
              variant="outlined"
              onClick={() => onClose()}
            >
              OK
            </Button>
            <br />
            <br />
          </div>
        )} */}
      </div>
    );
  };

  const GladiatorCards = () => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          paddingTop: 40,
          paddingBottom: 40,
          marginBottom: 40,
          textAlign: "center",
          width: "100%",
        },
        cards: {
          width: "100%",
        },
      })
    );
    const classes = useStyles();
    return (
      <Grid container className={classes.root} justifyContent={"center"}>
        <DuelCard />
        <Grid item className={classes.cards}>
          <Grid container justifyContent={"center"} spacing={10}>
            {ownerTokens.map((item, _) => (
              <GladiatorCard key={item} tokenId={item} />
            ))}
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const GladiatorCard = ({ tokenId }: { tokenId: string }) => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          width: 250,
          marginTop: 10,
          marginLeft: xs ? 0 : 15,
          borderRadius: 10,
          cursor: "pointer",
          "&:hover": {
            transform: "scale(1.01)",
            boxShadow: "#9c27b0 0px 2px 18px 0px",
            border: "none",
          },
          transition: "transform .2s",
        },
      })
    );
    const classes = useStyles();
    return (
      <img
        onClick={(e) => {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
          });
          e.stopPropagation();
          setSelectedGladiator(tokenId);
        }}
        className={classes.root}
        src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${tokenId}.png`}
        alt=""
      />
    );
  };

  const DuelCard = () => {
    const useStyles = makeStyles((theme: Theme) =>
      createStyles({
        root: {
          borderStyle: "dashed",
          borderColor: "gray",
          borderRadius: 20,
          padding: 20,
          marginTop: -30,
          marginBottom: 60,
        },
        image: {
          width: 250,
          borderRadius: 10,
          cursor: "pointer",
        },
        token: {
          width: 250,
          marginTop: 10,
          marginBottom: xs ? 20 : 0,
          marginLeft: xs ? 0 : 15,
          marginRight: xs ? 0 : 15,
          borderRadius: 10,
          cursor: "pointer",
          float: xs ? "none" : "left",
        },
        title: {
          height: 20,
          marginTop: -10,
          marginBottom: 20,
        },
      })
    );
    const classes = useStyles();

    const TokenId1 = () => {
      return duel ? (
        <div className={classes.token}>
          <div className={classes.title}>{metadata1.name}</div>
          <img
            onClick={(e) => {
              e.stopPropagation();
              window.open(links.openSeaToken + duel.tokenId1, "_blank");
            }}
            className={classes.image}
            src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${duel.tokenId1}.png`}
            alt=""
          />
        </div>
      ) : null;
    };

    const TokenId2 = () => {
      return selectedGladiator ? (
        <div className={classes.token}>
          <div className={classes.title}>{metadata2.name}</div>
          <img
            onClick={(e) => {
              e.stopPropagation();
              window.open(links.openSeaToken + selectedGladiator, "_blank");
            }}
            className={classes.image}
            src={`https://ipfs.io/ipfs/QmVzJuTQTNAVRrGCzDCTF4VaeMJc2dV91sc5Mew1JdHXpw/${selectedGladiator}.png`}
            alt=""
          />
        </div>
      ) : null;
    };

    return (
      <div className={classes.root}>
        <TokenId1 />
        <TokenId2 />
      </div>
    );
  };

  const setPropabilityText = (metadata: any) => {
    setMetadata2(metadata);
    const gladiator1Points = getPoints(metadata1.attributes);
    const gladiator2Points = getPoints(metadata.attributes);
    const combinedPoints = gladiator1Points + gladiator2Points;
    // const gladiator1chance = (gladiator1Points * 100) / combinedPoints;
    const gladiator2chance = (gladiator2Points * 100) / combinedPoints;
    setPropability((gladiator2chance | 0).toString());
  };

  const getPoints = (attributes: Array<any>) => {
    if (attributes.length === 0) return 0;
    let points = 0;
    for (var i = 0; i < 3; i++) {
      points += attributes[i].value;
    }
    return points;
  };

  const handleClickApprove = async () => {
    setLoading(true);
    const response = await Gladiator.approveDegenArena();
    setLoading(false);
    if (response.transactionHash) {
      setApproved(await Gladiator.isDegenArenaApproved());
    }
  };

  const Loading = () => <CircularProgress color="secondary" />;

  useEffect(() => {
    setLoading(true);
    (async () => {
      setApproved(await Gladiator.isDegenArenaApproved());

      if (!duel) return;
      let response = await axios.get(
        `https://ipfs.io/ipfs/QmPTShL8HVRMToBnvY5jXFACGm1b18oQMtLK4VXsCnBa9d/${duel.tokenId1}.json`
      );
      setMetadata1(response.data);

      if (!selectedGladiator) return;
      response = await axios.get(
        `https://ipfs.io/ipfs/QmPTShL8HVRMToBnvY5jXFACGm1b18oQMtLK4VXsCnBa9d/${selectedGladiator}.json`
      );
      setPropabilityText(response.data);
      setLoading(false);
    })();
    // eslint-disable-next-line
  }, [duel, selectedGladiator]);

  return duel ? (
    <div className={classes.root}>
      <Modal />
    </div>
  ) : null;
}
